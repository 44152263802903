<template>
  <div>
    <div style="margin: 5px">
      <label>
        {{ $t('shop.subcatalogNode.name') }}
      </label>
      <BFormInput
        v-model="name"
        :placeholder="$t('shop.subcatalogNode.name')"
        size="sm"
      />
      <label
        v-if="!node"
        class="mt-3 mb-0"
      >
        {{ $t('shop.subcatalogNode.parent') }}
      </label>

      <SubCatalogTree
        v-if="!node"
        class="subcatalog-tree"
        :node-id="''"
        :node-name="subCatalogName"
        :sub-catalog-id="subCatalogId"
        :nodes="otherNodes"
        :selected="[parentId]"
        style="font-size: 0.9rem;"
        @select="parentId = $event"
      />

      <label class="mr-3 small mt-3">
        <i class="fa-solid fa-code mr-1" />
        {{ $t('shop.availabilityRule') }}:
      </label>
      <BFormTextarea
        v-model="accessRule"
        size="sm"
        :rows="1"
        :placeholder="$t('shop.availabilityRule')"
        class="money-font"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SubCatalogTree from '../SubCatalogTree';

export default {
  props: {
    catalogId: String,
    subCatalogId: String,
    subCatalogName: String,
    node: Object,
    pending: Boolean,
    nodes: Array,
  },
  data: () => ({
    name: '',
    parentId: '',
    accessRule: '',
    error: false,
  }),
  components: {
    SubCatalogTree,
  },
  computed: {
    otherNodes() {
      return this.nodes
        .filter((x) => !this.node || x.nodeId !== this.node.nodeId);
      // .map((x) => ({
      //   value: x.nodeId,
      //   text: this.getPath(x.nodeId),
      // }))
      // .concat([
      //   {
      //     value: '',
      //     text: '-',
      //   },
      // ]);
    },
  },
  methods: {
    ...mapActions('shop', [
      'createShopSubCatalogNode',
      'updateShopSubCatalogNode',
      'deleteShopSubCatalogNode',
    ]),
    getPath(nodeId) {
      const node = this.nodes.find((x) => x.nodeId === nodeId);
      if (!node.parentId) {
        return node.name;
      }

      return `${this.getPath(node.parentId)}/${node.name}`;
    },
    submit() {
      if (this.node) {
        this.update();
      } else {
        this.add();
      }
    },
    update() {
      this.$emit('update:pending', true);
      this.error = false;
      this.updateShopSubCatalogNode({
        params: {
          catalogId: this.catalogId,
          subCatalogId: this.subCatalogId,
          nodeId: this.node.nodeId,
        },
        data: {
          name: this.name,
          accessRule: this.accessRule,
          parentId: this.parentId || '',
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.error = true;
          this.$emit('update:pending', false);
        });
    },
    add() {
      this.$emit('update:pending', true);
      this.error = false;
      this.createShopSubCatalogNode({
        params: {
          catalogId: this.catalogId,
          subCatalogId: this.subCatalogId,
        },
        data: {
          name: this.name,
          accessRule: this.accessRule,
          parentId: this.parentId || '',
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.error = true;
          this.$emit('update:pending', false);
        });
    },
    remove() {
      this.$emit('update:pending', true);
      this.deleteShopSubCatalogNode({
        params: {
          subCatalogId: this.subCatalogId,
          catalogId: this.catalogId,
          nodeId: this.node.nodeId,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.node) {
      this.name = this.node.name;
      this.accessRule = this.node.accessRule;
      this.parentId = this.node.parentId;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
