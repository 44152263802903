<template>
  <div
    class="d-flex align-items-center"
  >
    <div
      class="checkbox set-row set-row-hover"
      @click="$emit('toggle')"
    >
      <Checkbox
        :value="checked"
        :emit="false"
      />
    </div>

    <div
      class="d-flex flex-wrap flex-md-nowrap set-row flex-grow-1 set-row-hover"
      :class="{ selected }"
      @click="$emit('select')"
    >
      <template v-for="c in columns">
        <div
          v-if="c.key === 'day'"
          :key="c.key"
          style="width: 50px"
          class="money-font small font-weight-normal"
        >
          {{ singleOrderSchema ? "" : order.formattedDay }}
        </div>

        <div
          v-if="c.key === 'name'"
          :key="c.key"
          :class="c.key"
          class="flex-grow-1 pl-2 w-100"
        >
          <span class="d-inline-block payer-icon">
            <i
              v-if="payer.type === 'Organization'"
              class="fas fa-building pr-2 text-center"
              style="width: 20px"
            />
            <i
              v-else-if="payer.type === 'Person'"
              class="fas fa-person-simple pr-2 text-center"
              style="width: 20px"
            />
          </span>
          <span>{{ payer.name }}</span>
        </div>
        <div
          v-if="c.key === 'code'"
          :key="c.key"
          v-tippy="{
            trigger: (payer.code || '').length > 15 ? 'mouseenter' : 'manual',
            delay: 500
          }"
          :class="c.key"
          :content="payer.code"
          class="text-secondary align-self-center small px-2 text-truncate"
        >
          {{ payer.code }}
        </div>

        <span
          v-if="c.key === 'productSetName'"
          :key="c.key"
          :class="c.key"
        >
          {{ order.set.name }}
        </span>

        <span
          v-if="c.key === 'created'"
          :key="c.key"
          :class="c.key"
        >
          {{ formatDate(order.created) }}
        </span>

        <div
          v-if="c.key === 'paid'"
          :key="c.key"
          :class="c.key"
        >
          <i
            v-if="!order.isPaid"
            v-tippy
            :content="$t('shop.notPaid')"
            class="fas fa-hand-holding-dollar text-warning pl-2"
          />
        </div>
        <div
          v-if="c.key === 'comment'"
          :key="c.key"
          :class="c.key"
        >
          {{ order.comment || '-' }}
        </div>
        <div
          v-if="c.key === 'delivered'"
          :key="c.key"
          :class="c.key"
        >
          <i
            v-if="order.delivered"
            class="fas fa-square-check text-primary"
          />
        </div>
        <div
          v-if="c.key === 'space'"
          :key="c.key"
          class="flex-grow-1 align-self-center"
        >
          &nbsp;
        </div>
        <div
          v-if="c.key === 'count'"
          :key="c.key"
          :class="c.key"
          class="money-font text-right px-2"
        >
          <span>{{ order.count }}</span>
        </div>
        <div
          v-if="c.key === 'discount'"
          :key="c.key"
          :class="c.key"
          class="text-right px-2"
          style="width: 60px;"
        >
          <span>{{ order.discountName }}</span>
        </div>
        <div
          v-if="c.key === 'price'"
          :key="c.key"
          :class="c.key"
          class="money-font column-divider text-right d-flex align-items-center justify-content-end"
        >
          <span v-if="order.discountName">
            <Tippy>

              <template #trigger>
                <i class="fas fa-badge-percent text-primary pr-2" />
              </template>

              {{ order.discountName }}
              <div class="text-strikethrough">
                {{ formatCurrency(order.priceBeforeDiscount, order.currency) }}
              </div>

            </Tippy>
          </span>

          {{ formatCurrency((order.unitPrice * order.count), order.currency) }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    checked: Boolean,
    selected: Boolean,
    columns: Array,
    groupBy: String,
    order: Object,
    payer: Object,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
    ...mapGetters('shop', [
      'singleOrderSchema',
      'catalogDetails',
    ]),
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./style.scss";

  .set-row-hover:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }

  .set-row {
    border-bottom: 1px solid #ddd;
    padding: 2px 2px;
    cursor: pointer;
    height: 32px;
    align-items: center;

    &.no-padding {
      padding: 0px;
    }

    &.checkbox {
      border-right: 1px solid rgba(100, 100, 100, 0.2);
      display: flex;
      justify-content: center;
    }

    &.selected {
      background-color: rgba($blue, 0.2);
    }
  }

  .payer-icon {
    color: $light-gray;
  }
</style>
