var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex client-row justify-content-between position-relative",class:{
    edited: _vm.edited,
    'client-row-hover': _vm.hideDetails,
  }},[_c('div',{staticClass:"client-checkbox",class:{ 'client-row-hover': !_vm.hideDetails },staticStyle:{"border-right":"1px solid rgba(100, 100, 100, 0.2)"},attrs:{"data-test":"client-select"},on:{"click":_vm.toggleSelect}},[_vm._t("select",function(){return [_c('Checkbox',{staticStyle:{"position":"relative","top":"9px"},attrs:{"value":_vm.selected,"emit":false,"un-color":_vm.edited ? '#fff' : '#dedede'},on:{"update:value":function($event){_vm.selected=$event}}})]})],2),_c('div',{staticClass:"d-flex flex-wrap flex-md-nowrap flex-grow-1",class:{ 'client-row-hover': !_vm.hideDetails },on:{"click":_vm.select}},[_vm._l((_vm.layout),function(c){return [(c.key === 'name')?_c('div',{key:c.key,staticClass:"flex-grow-1 pl-2 w-100 client-name"},[_c('span',{staticClass:"d-inline-block payer-icon"},[(_vm.type === 'Organization')?_c('i',{staticClass:"fas fa-building pr-2 text-center",staticStyle:{"width":"20px"}}):(_vm.type === 'Person')?_c('i',{staticClass:"fas fa-person-simple pr-2 text-center",staticStyle:{"width":"20px"}}):_vm._e()]),_c('span',[_vm._v(_vm._s(_vm.name))])]):_vm._e(),(c.key === 'clientCode' || c.key === 'code')?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
          trigger: (_vm.code || '').length > 15 ? 'mouseenter' : 'manual',
          delay: 500
        }),expression:"{\n          trigger: (code || '').length > 15 ? 'mouseenter' : 'manual',\n          delay: 500\n        }"}],key:c.key,staticClass:"text-secondary align-self-center client-code small px-2 text-truncate",attrs:{"content":_vm.code}},[_vm._v(" "+_vm._s(_vm.code)+" ")]):_vm._e(),(c.type === 'property')?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
          trigger: (_vm.properties[c.key] || '').length > 15 ? 'mouseenter' : 'manual',
          delay: 500
        }),expression:"{\n          trigger: (properties[c.key] || '').length > 15 ? 'mouseenter' : 'manual',\n          delay: 500\n        }"}],key:c.key,staticClass:"align-self-center client-code small px-2 text-truncate",attrs:{"content":_vm.properties[c.key]}},[_c('PropertyValue',{attrs:{"code":c.key,"value":_vm.properties[c.key]}})],1):_vm._e(),(c.type === 'group')?_c('div',{key:c.key,staticClass:"align-self-center client-code px-2 text-truncate text-primary balance-info"},[(_vm.groups.includes(c.key))?_c('i',{staticClass:"fas fa-square-check"}):_c('span',[_vm._v(" ")])]):_vm._e(),(c.key === 'space')?_c('div',{key:c.key,staticClass:"flex-grow-1 align-self-center"},[_vm._v("   ")]):_vm._e(),((c.key === 'categories' || c.type === 'category') && _vm.balance && !_vm.hideDetails)?_c('div',{key:c.key,staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-items-center position-relative flex-grow-1"},[_c('div',{staticClass:"text-left flex-grow-1 d-flex"},_vm._l((_vm.balance.filter(y => c.key === 'categories' || y.categoryId === c.key)),function(b){return _c('div',{key:`${b.categoryId}_${b.currency}`,staticClass:"d-flex align-items-center justify-content-between text-right balance-info",class:{
                light: b.balance === 0,
                red: b.balance < 0,
                blue: b.balance > 0,
              },staticStyle:{"width":"150px"}},[_c('CategoryInfo',{attrs:{"category-id":b.categoryId,"color-only":""}}),_c('span',{staticClass:"money-font text-nowrap small balance"},[(b.balance > 0)?_c('span',[_vm._v("+")]):_vm._e(),_vm._v(_vm._s(_vm.formatCurrency(b.balance, b.currency))+" ")])],1)}),0)])]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }