<template>
  <div>
    <BModal
      v-model="catalogForm"
      hide-footer
      hide-header
      size="lg"
    >
      <CatalogForm
        @close="catalogForm = false"
        @open="openCatalog"
        @refresh="request"
      />
    </BModal>

    <div class="d-flex justify-content-between">
      <div class="page-title-wrapper">
        <div class="page-title active">
          {{ $t('shop.catalog.catalogs') }}
        </div>
      </div>
      <div class="page-title-wrapper">
        <button
          class="btn btn-sm btn-primary"
          @click="catalogForm = true"
        >
          <i class="fas fa-plus mr-1" />
          {{ $t('shop.catalog.add') }}
        </button>
      </div>
    </div>
    <Loader v-if="pending" />
    <CatalogRow
      v-for="c in catalogs"
      :key="c.id"
      :catalog="c"
      class="mb-2"
      @edit="openCatalog(c.id)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CatalogForm from './CatalogForm';
import CatalogRow from './CatalogRow';

export default {
  data: () => ({
    editedId: null,
    catalogForm: false,
    pending: false,
  }),
  components: {
    CatalogRow,
    CatalogForm,
  },
  computed: {
    ...mapGetters(['payeeId']),
    ...mapGetters('shop', ['catalogs']),
  },
  methods: {
    ...mapActions('shop', ['getShopCatalogs']),
    openCatalog(id) {
      this.$router.push(`/payee/${this.payeeId}/shop/${id}/orders`);
    },
    request() {
      this.pending = true;
      this.getShopCatalogs()
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.$emit('page', 'catalogs');
    this.request();
  },
};
</script>

<style lang="scss" scoped>

</style>
