<template>
  <EditableList
    ref="list"
    :list="options"
    :pending="pending"
    :form-add-title="$t('shop.addProduct')"
    :form-edit-title="$t('shop.editProduct')"
    @submit="$refs.form.submit()"
    @remove="$refs.form.remove()"
    @selected="selected = $event"
  >
    <template #form="{edited}">
      <ProductForm
        ref="form"
        :product="getProduct(edited)"
        :catalog-id="catalogId"
        :pending.sync="pending"
        @close="$refs.list.close()"
        @refresh="$emit('refresh')"
      />
    </template>
  </EditableList>
</template>

<script>
import EditableList from '@/components/settings/EditableList';
import ProductForm from '@/components/shop/ProductForm';
import { mapGetters } from 'vuex';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    selected: null,
    pending: false,
  }),
  components: {
    EditableList,
    ProductForm,
  },
  computed: {
    ...mapGetters('shop', ['products']),
    options() {
      return this.products.map((x) => ({
        id: x.id,
        name: x.name,
      }));
    },
  },
  methods: {
    getProduct(id) {
      return this.products.find((x) => x.id === id);
    },
  },
};
</script>
