<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <label class="mb-0">
        <i class="far fa-file pr-1" />
        {{ $t('menu.import') }}
      </label>

      <div @click="$emit('close')">
        <i class="fas fa-times secondary-icon" />
      </div>
    </div>
    <FileUpload
      v-if="!importFile"
      class="upload h-100 mt-1 d-flex align-items-center justify-content-center"
      @upload="readFile"
    />
    <div v-if="importPending">
      <Loader />
    </div>

    <div v-else-if="importResult">
      <template v-if="importResult.errors && importResult.errors.length > 0">
        <div class="font-weight-bold">
          {{ $t('import.errors') }}
        </div>
        <div
          v-for="(error, i) in importResult.errors"
          :key="`product_${i}`"
        >
          <ShopImportError v-bind="error" />
        </div>

        <hr>

        <div
          v-if="importResult.errors.length > 0"
          class="d-flex justify-content-end"
        >
          <div
            style="max-width: 500px;"
            class="d-flex w-100"
          >
            <div class="w-50 mx-2">
              <button
                class="btn btn-sm btn-secondary btn-block"
                @click="importResult = null; importFile = null"
              >
                {{ $t('general.cancel') }}
              </button>
            </div>
            <div class="w-50 mx-2">
              <button
                class="btn btn-sm btn-primary btn-block"
                @click="publish"
              >
                {{ $t('shop.continueWithErrors') }}
                <i class="fa fa-arrow-right ml-2" />
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload';
import ShopImportError from '@/components/shop/ShopImportError';
import { mapActions } from 'vuex';

export default {
  props: {
    menuNodeId: String,
    catalogId: String,
  },
  data() {
    return {
      importFile: null,
      importPending: false,
      importResult: null,
    };
  },
  components: {
    FileUpload,
    ShopImportError,
  },
  methods: {
    ...mapActions('shop', [
      'importDeliveryPlan',
    ]),
    publish() {
      this.$emit('import', this.importResult.entries);
      this.$emit('close');
    },
    readFile(file) {
      const formData = new FormData();
      formData.append('file', file);

      this.importFile = file;
      this.importPending = true;

      this.importDeliveryPlan({
        params: {
          catalogId: this.catalogId,
          menuNodeId: this.menuNodeId,
        },
        data: formData,
      })
        .then(({ data }) => {
          this.importResult = data;

          if (data.errors.length === 0) {
            this.publish();
          }
        })
        .finally(() => {
          this.importPending = false;
        });
    },
  },
};
</script>
