<template>
  <div>
    <div style="margin: 5px">
      <label>
        {{ $t('shop.status.name') }}
      </label>
      <BFormInput
        v-model="name"
        :placeholder="$t('shop.status.name')"
        size="sm"
      />
    </div>
    <div style="margin: 5px; margin-top: 15px">
      <ColorPicker
        :selected.sync="colorCode"
      />
    </div>
  </div>
</template>

<script>
import ColorPicker from '@/components/settings/bills/ColorPicker';
import { mapActions } from 'vuex';

export default {
  props: {
    catalogId: String,
    orderStatus: Object,
    pending: Boolean,
  },
  data: () => ({
    name: '',
    colorCode: 'default',
    layout: 0,
    error: false,
  }),
  components: {
    ColorPicker,
  },
  computed: {
  },
  methods: {
    ...mapActions('shop', [
      'createOrderStatus',
      'updateOrderStatus',
      'deleteOrderStatus',
    ]),
    submit() {
      if (this.orderStatus) {
        this.update();
      } else {
        this.add();
      }
    },
    update() {
      this.$emit('update:pending', true);
      this.error = false;
      this.updateOrderStatus({
        params: {
          catalogId: this.catalogId,
          orderStatusId: this.orderStatus.id,
        },
        data: {
          name: this.name,
          color: this.colorCode,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.error = true;
          this.$emit('update:pending', false);
        });
    },
    add() {
      this.$emit('update:pending', true);
      this.error = false;
      this.createOrderStatus({
        params: {
          catalogId: this.catalogId,
        },
        data: {
          name: this.name,
          color: this.colorCode,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.error = true;
          this.$emit('update:pending', false);
        });
    },
    remove() {
      this.$emit('update:pending', true);
      this.deleteOrderStatus({
        params: {
          catalogId: this.catalogId,
          orderStatusId: this.orderStatus.id,
        },
      })
        .then(() => {
          this.$emit('update:pending', false);
          this.$emit('close');
        })
        .catch(() => {
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.orderStatus) {
      this.name = this.orderStatus.name;
      this.colorCode = this.orderStatus.color;
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
