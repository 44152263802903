<template>
  <div class="pt-3">
    <div class="form-group">
      <label for="paymentTitle">{{ $t('general.name') }}</label>
      <input
        v-model="name"
        class="form-control"
        :placeholder="$t('general.name')"
      >
    </div>
    <div class="form-group">
      <label for="paymentTitle">{{ $t('general.description') }}</label>
      <textarea
        v-model="description"
        class="form-control"
        :placeholder="$t('general.description')"
      />
    </div>

    <div
      v-for="subCatalog in subCatalogsSorted"
      :key="subCatalog.subCatalogId"
      class="mb-3 form-box"
    >
      <div class="d-flex justify-content-between">
        <label class="section-title mb-2">
          {{ subCatalog.name }}
        </label>
        <AssignSubCatalogDropdown
          :sub-catalog-id="subCatalog.subCatalogId"
          :selected.sync="assignments"
        />
      </div>
      <div class="px-2 first">
        <div
          v-for="node in subCatalog.nodes"
          :key="node.nodeId"
          class="product-row"
        >
          <i
            class="fas fa-times secondary-icon"
            @click="removeNode(node.nodeId)"
          />
          <span class="font-weight-bold pl-2">
            {{ node.name }}
          </span>
        </div>

        <div v-if="subCatalog.nodes.length === 0">
          -
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between form-group">
      <label class="mb-0 mt-1">
        {{ $t('shop.price') }}
      </label>
      <MoneyInput
        v-model="price"
        :currency.sync="currency"
        class="money-input"
      />
    </div>

    <div class="form-box">
      <div class="d-flex justify-content-between">
        <label class="mb-0 mt-1">
          {{ $t('shop.products') }}
        </label>

        <ProductAddDropdown
          :allow-create="!listing"
          :selected.sync="productIds"
        />
      </div>
      <div>
        <div
          v-for="id in productIds"
          :key="id"
          class="product-row"
        >
          <i
            class="fas fa-times secondary-icon mr-2"
            @click="removeProduct(id)"
          />
          {{ productName(id) }}
        </div>
      </div>
    </div>

    <div class="form-box mt-4">
      <div class="d-flex my-1">
        <BFormCheckbox
          v-model="orderFromEnabled"
          switch
          class="switch white-switch white-switch-sm"
        />
        <label>
          {{ $t('shop.orderFrom') }}
        </label>
      </div>

      <TimeLimitInput
        v-if="orderFromEnabled"
        :value.sync="orderFrom"
        :single-order-schema="singleOrderSchema"
        :label="$t('shop.orderFrom')"
        default-reference="ListingMonthStart|0.09:00:00.false"
      />

      <hr class="mb-2">

      <div class="d-flex my-1">
        <BFormCheckbox
          v-model="orderToEnabled"
          switch
          class="switch white-switch white-switch-sm"
        />
        <label>
          {{ $t('shop.orderTo') }}
        </label>
      </div>

      <TimeLimitInput
        v-if="orderToEnabled"
        :value.sync="orderTo"
        :single-order-schema="singleOrderSchema"
        :label="$t('shop.orderTo')"
        default-reference="ListingMonthEnd|0.09:00:00.false"
      />

      <hr class="mb-2">

      <div class="d-flex my-1">
        <BFormCheckbox
          v-model="cancelToEnabled"
          switch
          class="switch white-switch white-switch-sm"
        />
        <label>
          {{ $t('shop.cancelTo') }}
        </label>
      </div>

      <TimeLimitInput
        v-if="cancelToEnabled"
        :value.sync="cancelTo"
        :single-order-schema="singleOrderSchema"
        :label="$t('shop.cancelTo')"
        default-reference="ListingDay|-1.22:00:00.false"
      />

      <hr class="mb-2">

      <div class="d-flex my-1">
        <BFormCheckbox
          v-model="limitCountEnabled"
          switch
          class="switch white-switch white-switch-sm"
        />
        <label>
          {{ $t('shop.listing.limitCount') }}
        </label>
      </div>
      <BFormInput
        v-if="limitCountEnabled"
        v-model="limitCount"
        type="number"
        :placeholder="$t('shop.listing.limitCount')"
      />
    </div>

    <hr>
    <div>
      <SaveButton
        :pending="pending"
        @cancel="$emit('close')"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import SaveButton from '@/components/SaveButton';
import MoneyInput from '@/components/utils/MoneyInput';
import { mapActions, mapGetters } from 'vuex';
import TimeLimitInput from '../TimeLimitInput';
import AssignSubCatalogDropdown from './AssignSubCatalogDropdown';
import ProductAddDropdown from './ProductAddDropdown';

export default {
  props: {
    listing: Object,
  },
  data: () => ({
    edit: false,
    name: '',
    description: '',
    price: 0,
    currency: 'pln',
    pending: false,
    productIds: [],
    assignments: [],
    orderFrom: '',
    orderTo: '',
    cancelTo: '',
    limitCount: -1,
    orderFromEnabled: false,
    orderToEnabled: false,
    cancelToEnabled: false,
    limitCountEnabled: false,
  }),
  components: {
    TimeLimitInput,
    ProductAddDropdown,
    AssignSubCatalogDropdown,
    MoneyInput,
    SaveButton,
  },
  computed: {
    ...mapGetters(['groups']),
    ...mapGetters('shop', [
      'products',
      'catalogDetails',
      'singleOrderSchema',
    ]),
    subCatalogOrder() {
      return this.catalogDetails.subCatalogs.reduce((acc, curr) => {
        acc[curr.subCatalogId] = curr.ordinal;
        return acc;
      }, {});
    },
    subCatalogsSorted() {
      return this.catalogDetails.subCatalogs
        .filter((x) => x.subCatalogId !== '#products')
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((x) => ({
          ...x,
          nodes: this.assignments
            .filter(({ subCatalogId }) => subCatalogId === x.subCatalogId)
            .map(({ nodeId, subCatalogId }) => {
              if (subCatalogId === '#groups') {
                const group = this.groups.find((g) => g.code === nodeId);
                if (!group) return null;
                return {
                  nodeId: group.code,
                  name: group.code === '#all' ? this.$t('company.all') : group.path,
                };
              }

              return this.catalogDetails.subCatalogNodes
                .filter((n) => n.subCatalogId === x.subCatalogId)
                .find((s) => s.nodeId === nodeId);
            })
            .filter((n) => n)
            .sort((a, b) => {
              if (x.subCatalogId === '#groups') {
                return a.name.localeCompare(b.name);
              }

              return a.ordinal - b.ordinal;
            }),
        }));
    },
  },
  watch: {
    limitCountEnabled(v) {
      if (v) {
        if (this.limitCount < 0) {
          this.limitCount = 1;
        }
      } else {
        this.limitCount = -1;
      }
    },
    orderFromEnabled(v) {
      if (!v) {
        this.orderFrom = '';
      }
    },
    orderToEnabled(v) {
      if (!v) {
        this.orderTo = '';
      }
    },
    cancelToEnabled(v) {
      if (!v) {
        this.cancelTo = '';
      }
    },
  },
  methods: {
    ...mapActions('shop', [
      'getProducts',
      'createCatalogListing',
      'updateCatalogListing',
    ]),
    productName(id) {
      if (id === '#new') {
        return this.name || this.$t('shop.listing.addSameAsListing');
      }
      return this.products.find((x) => x.id === id)?.name;
    },
    subCatalogName(nodeId) {
      const node = this.getNode(nodeId);
      if (node && node.subCatalogId === '#groups') {
        return this.$t('groups.groups');
      }
      return this.catalogDetails.subCatalogs.find((x) => x.subCatalogId === node?.subCatalogId)?.name;
    },
    getNode(nodeId) {
      const group = this.groups.find((x) => x.code === nodeId);
      if (group) {
        return {
          ...group,
          name: group.code === '#all' ? this.$t('company.all') : group.path,
          subCatalogId: '#groups',
        };
      }
      return this.catalogDetails.subCatalogNodes.find((x) => x.nodeId === nodeId);
    },
    removeNode(id) {
      this.assignments = this.assignments.filter((x) => x.nodeId !== id);
    },
    removeProduct(id) {
      this.productIds = this.productIds.filter((x) => x !== id);
    },
    save() {
      this.pending = true;
      if (this.listing) {
        this.update();
      } else {
        this.create();
      }
    },
    update() {
      this.updateCatalogListing({
        params: {
          catalogId: this.catalogDetails.id,
          listingId: this.listing.id,
        },
        data: {
          name: this.name,
          description: this.description,
          price: this.price,
          currency: this.currency,
          productIds: this.productIds.filter((x) => x !== '#new'),
          orderFrom: this.orderFrom || '',
          orderTo: this.orderTo || '',
          cancelTo: this.cancelTo || '',
          assignments: this.assignments,
          countLimit: this.limitCount,
        },
      })
        .then(() => {
          this.$emit('close');
        })
        .finally(() => {
          this.pending = false;
        });
    },
    create() {
      this.createCatalogListing({
        params: {
          catalogId: this.catalogDetails.id,
        },
        data: {
          name: this.name,
          description: this.description,
          price: this.price,
          currency: this.currency,
          productIds: this.productIds,
          orderFrom: this.orderFrom || '',
          orderTo: this.orderTo || '',
          cancelTo: this.cancelTo || '',
          assignments: this.assignments,
          countLimit: this.limitCount,
        },
      })
        .then(() => {
          this.$emit('close');
          this.getProducts({
            params: {
              catalogId: this.catalogDetails.id,
            },
          });
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    if (this.listing) {
      this.name = this.listing.name;
      this.description = this.listing.description;
      this.price = this.listing.price;
      this.currency = this.listing.currency;
      this.productIds = this.listing.productIds;
      this.assignments = this.listing.assignments.slice();
      this.orderFrom = this.listing.orderFrom;
      this.orderFromEnabled = !!this.listing.orderFrom;
      this.orderTo = this.listing.orderTo;
      this.orderToEnabled = !!this.listing.orderTo;
      this.cancelTo = this.listing.cancelTo;
      this.cancelToEnabled = !!this.listing.cancelTo;
      this.limitCount = this.listing.countLimit;
      this.limitCountEnabled = this.listing.countLimit !== -1;
    }
  },
};
</script>

<style lang="scss" scoped>
.product-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  padding-top: 15px;
}

.switch {
  max-width: 10px;
  padding-left: 2.5rem;
  position: relative;
  right: -1rem;
  top: -1px;
}

.form-box {
  padding: 5px 10px 10px;
  border: 1px solid #efefef;
}

.product-row {
  display: flex;
  align-items: center;
  margin-top: 7px;
  background-color: #fafafa;
  border-radius: 15px;
}
</style>
