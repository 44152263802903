import axios from '@/api/axios';
import lang from '@/lang';
import Vue from 'vue';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
    listings: [],
  },
})
  .get({
    action: 'getCatalogListings',
    property: 'listings',
    path: ({ catalogId }) => `/shop/${catalogId}/listings`,
  })
  .post({
    action: 'createCatalogListing',
    path: ({ catalogId }) => `/shop/${catalogId}/listings`,
    onSuccess: (s, { data }) => {
      s.listings.push(data);
    },
  })
  .put({
    action: 'updateCatalogListing',
    path: ({ catalogId, listingId }) => `/shop/${catalogId}/listings/${listingId}`,
    onSuccess: (s, { data }) => {
      const index = s.listings.findIndex((x) => x.id === data.id);
      Vue.set(s.listings, index, data);
    },
  })
  .delete({
    action: 'deleteCatalogListing',
    path: ({ catalogId, listingId }) => `/shop/${catalogId}/listings/${listingId}`,
    onSuccess: (s, _, a, { params }) => {
      s.listings = s.listings.filter((x) => x.id !== params.listingId);
    },
  })
  .post({
    action: 'offerImportValidate',
    path: ({ catalogId }) => `/shop/${catalogId}/listings/import/validate`,
  })
  .post({
    action: 'offerImport',
    property: 'listings',
    path: ({ catalogId }) => `/shop/${catalogId}/listings/import`,
  })
  .get({
    action: 'offerExport',
    path: ({ catalogId }) => `/shop/${catalogId}/listings/export`,
    requestConfig: {
      responseType: 'blob',
    },
  })
  .post({
    action: 'findPayersOffer',
    path: ({ catalogId }) => `/shop/${catalogId}/listings/find`,
  })
  .getStore();

store.getters = {
  listings: (s) => s.listings,
  timeReferenceTypes: () => [
    {
      value: 'ListingMonthStart',
      text: lang.t('shop.timeReference.listingMonthStart'),
    },
    {
      value: 'ListingMonthEnd',
      text: lang.t('shop.timeReference.listingMonthEnd'),
    },
    {
      value: 'ListingDay',
      text: lang.t('shop.timeReference.listingDay'),
    },
  ],
  parseTimeReference: () => (text) => {
    const parts = text.split('|');
    if (parts.length !== 2) {
      return null;
    }

    // [this.type] = parts;
    const clockParts = parts[1].split('.');
    if (clockParts.length !== 3) {
      return null;
    }

    // [this.dayOffset] = clockParts;
    const hourParts = clockParts[1].split(':');

    return {
      type: parts[0],
      dayOffset: Number.parseInt(clockParts[0], 10),
      skipWeekend: clockParts[2].toLowerCase() === 'true',
      hour: `${hourParts[0]}:${hourParts[1]}`,
    };
  },
};

export default store;
