<template>
  <!-- :form-add-title="$t('shop.subcatalogNode.add')"
    :form-edit-title="selected === orderStatus.id
      ? $t('shop.subcatalog.edit') : $t('shop.subcatalogNode.edit')" -->
  <EditableList
    ref="list"
    :list="options"
    :pending="pending"
    @submit="$refs.form.submit()"
    @remove="$refs.form.remove()"
    @selected="selected = $event"
  >
    <!-- <template #list="{ list, toggle }">
      <div style="min-height: 500px">
        <div
          class="sub-catalog-row"
          :class="{ selected: !subCatalog || selected === subCatalog.subCatalogId }"
          @click="toggle(subCatalog.subCatalogId)"
        >
          {{ subCatalog?.name }}
        </div>
        <SubCatalogsList
          class="pl-4"
          :list="list"
          :toggle="toggle"
          :selected="selected"
          :root-id="''"
          @orderUpdated="updateNodeOrder"
        />
        <div
          v-if="orderSaved"
          class="text-success mt-2 small text-center"
        >
          {{ $t('general.saved') }}
        </div>
      </div>
    </template> -->

    <template #form="{edited}">
      <OrderStatusForm
        v-if="!orderStatus || edited === orderStatus.id"
        ref="form"
        :order-status="orderStatus"
        :pending.sync="pending"
        :catalog-id="catalogId"
        @close="$emit('close')"
      />
    </template>
  </EditableList>
</template>

<script>
import EditableList from '@/components/settings/EditableList';
import { mapActions } from 'vuex';
import OrderStatusForm from './OrderStatusForm';
// import SubCatalogNodeForm from './SubCatalogNodeForm';
// import SubCatalogsList from './SubCatalogsList';

export default {
  props: {
    catalogId: String,
    statuses: Array,
  },
  data: () => ({
    pending: false,
    selected: null,
    orderSaved: false,
  }),
  components: {
    EditableList,
    OrderStatusForm,
  },
  computed: {
    options() {
      return this.statuses;
    },
    orderStatus() {
      return this.statuses.find((x) => x.id === this.selected);
    },
  },
  methods: {
    ...mapActions('shop', [
      'updateShopSubCatalogNodesOrder',
    ]),
    updateNodeOrder({ parentId, order }) {
      this.updateShopSubCatalogNodesOrder({
        params: {
          catalogId: this.catalogId,
          subCatalogId: this.subCatalog.subCatalogId,
        },
        data: {
          parentId,
          orderedIds: Object.keys(order)
            .sort((a, b) => order[a] - order[b]),
        },
      })
        .then(() => {
          this.orderSaved = true;

          setTimeout(() => {
            this.orderSaved = false;
          }, 3000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-catalog-row  {
  padding: 0.3rem 0.6rem;
  border-radius: 10px;
  border: 2px solid #eee;
  background-color: #fafafa;
  margin-top: 6px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  transition: border-color 300ms, margin-right 300ms;

  &:hover {
    border-color: #999;
  }
  &.selected {
    margin-right: -20px;
    background-color: rgba($blue, 0.1);
    border-color: $darkblue;
  }
}

.close {
  font-size: 28px;
  padding-right: 2px;
  margin-top: -2px;
}
</style>
