<template>
  <div
    ref="calendarContent"
    class="flex-grow-1"
    :class="{ pending }"
  >
    <div
      class="d-flex align-items-end justify-content-between mb-2"
      style="margin-top: -10px"
    >
      <WeekSelect
        @move="moveDirection = $event"
        @dates="dates = $event"
      />
      <div>
        <div class="d-flex align-items-end justify-content-end">
          <div>
            <ReportCheckbox
              v-model="showWeekends"
              text="Weekend"
              class="checkbox-switch"
            />
          </div>

          <div
            v-tippy
            class="secondary-icon ml-3"
            :content="$t('shop.offer.export')"
            @click="exportMenu"
          >
            <i
              v-if="!exportPending"
              class="fas fa-download"
            />
            <Loader
              v-else
              color="#666"
              size="15px"
              style="position: relative; top: 2px"
            />
          </div>
          <div
            v-tippy
            class="secondary-icon ml-3"
            :content="$t('shop.offer.upload')"
            @click="showUpload = true"
          >
            <i class="fa-solid fa-upload" />
          </div>

          <button
            :disabled="updatedCount === 0"
            class="btn btn-sm btn-primary px-4 ml-3"
            @click="save"
          >
            {{ $t('general.save') }}
            ({{ updatedCount }})
          </button>
        </div>
      </div>
    </div>

    <div v-if="showUpload">
      <hr>

      <DeliveryPlanImport
        class="m-3"
        :catalog-id="catalogId"
        :menu-node-id="menuNodeId"
        @close="showUpload = false"
        @import="importData"
      />
    </div>
    <div
      v-else
      @mouseleave="hover = { day: '', dietId: '', productId: '' }"
    >
      <div
        class="row no-gutters position-relative calendar-header"
        :style="{ marginRight: `${scrollbarWidth}px`}"
      >
        <div
          class="day-column"
          :style="{ width: `${columnWidth}%` }"
        >
          <div
            class="day-header"
          >
            {{ $t('shop.product') }}
          </div>
        </div>
        <div
          v-if="diets.length > 1"
          class="day-column"
          :style="{ width: `${columnWidth}%` }"
        >
          <div
            class="day-header"
          >
            {{ $t('shop.diet') }}
          </div>
        </div>
        <div
          class="position-relative"
          :style="{
            width: `${columnWidth*7}%`,
            overflow: animation > 0 ? 'hidden' : 'visible'
          }"
        >
          <Transition
            :name="moveDirection"
            @before-enter="animation += 1"
            @after-leave="animation -= 1"
          >
            <div
              :key="currentDays[0]"
              class="w-100"
            >
              <div
                class="position-absolute w-100 d-flex no-gutters"
              >
                <div
                  v-for="d in currentDays"
                  :key="d"
                  class="col day-column"
                  :class="{ today: today === d }"
                >
                  <div class="remove-tooltip">
                    <div
                      class="secondary-icon bill-button px-3"
                      @click="deleteDay(d)"
                    >
                      <i class="fas fa-xmark pr-1" />
                      {{ $t('form.clearAll') }}
                    </div>
                  </div>
                  <div
                    class="day-header"
                    :class="{
                      highlight: selected.day === d,
                      hover: hover.day === d
                    }"
                    @mouseenter="hover = { day: d, dietId: '', productId: '' }"
                  >
                    {{ formatWeekDay(d) }}
                    <div class="day-number">
                      {{ formatDay(d) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>

      <div
        class="row no-gutters mx-0 calendar flex-grow-1"
      >
        <div
          class="day-column"
          :style="{ width: `${columnWidth}%` }"
        >
          <div
            v-for="p in products"
            :key="p.id"
            class="product-name"
            :style="{ height: `${rowHeight * diets.length}px` }"
          >
            {{ p.name }}
          </div>
        </div>
        <div
          v-if="diets.length > 1"
          class="day-column"
          :style="{ width: `${columnWidth}%` }"
        >
          <div
            v-for="p in products"
            :key="p.id"
          >
            <div
              v-for="d in diets"
              :key="d.nodeId"
              class="diet-name"
              :class="{
                hover: hover.dietId === d.nodeId && hover.productId === p.id,
                highlight: selected.dietId === d.nodeId && selected.productId === p.id
              }"
              @mouseenter="hover = { day: '', dietId: d.nodeId, productId: p.id }"
            >
              {{ d.name }}
            </div>
          </div>
        </div>
        <div
          class="calendar-wrapper"
          :style="{ width: `${columnWidth * 7}%` }"
        >
          <Transition
            :name="moveDirection"
          >
            <div
              :key="currentDays[0]"
              class="w-100 h-100"
            >
              <div class="products-wrapper">
                <div
                  v-for="p in products"
                  :key="p.id"
                >
                  <div
                    v-for="d in diets"
                    :key="d.nodeId"
                    class="product-row d-flex no-gutters"
                    :class="{
                      hover: hover.dietId === d.nodeId && hover.productId === p.id,
                      highlight: selected.dietId === d.nodeId && selected.productId === p.id
                    }"
                  >
                    <div
                      v-for="day in currentDays"
                      :key="day"
                      class="col day-column content-cell"
                      :class="{
                        today: today === day,
                        updated: isUpdated(getKey(day, d.nodeId, p.id)),
                        hover: day === hover.day,
                        highlight: day === selected.day
                      }"
                      :style="{ width: `${(1/7)*100}%` }"
                      @mouseenter="hover = { day, dietId: d.nodeId, productId: p.id }"
                      @click.stop="toggleSelected({ day, dietId: d.nodeId, productId: p.id })"
                    >
                      <DeliveryPlanCell
                        :day="day"
                        :diet-id="d.nodeId"
                        :product-id="p.id"
                        :edited="getKey(selected) === getKey(day, d.nodeId, p.id)"
                        :initial="calendar[getKey(day, d.nodeId, p.id)]"
                        :updated.sync="updated[getKey(day, d.nodeId, p.id)]"
                        @close="selected = { day: '', dietId: '', productId: '' }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportCheckbox from '@/components/reports/ReportCheckbox';
import DeliveryPlanCell from '@/components/shop/delivery-plan/DeliveryPlanCell';
import DeliveryPlanImport from '@/components/shop/delivery-plan/DeliveryPlanImport';
import WeekSelect from '@/components/utils/WeekSelect';
import getFileName from '@/utils/file-name';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    menuNodeId: String,
    catalogId: String,
  },
  data: () => ({
    today: moment().startOf('day').format(),
    calendar: {},
    updated: {},
    pending: false,
    aiPending: false,
    dates: null,
    currentDays: [],
    moveDirection: '',
    rowHeight: 70,
    showUpload: false,
    selected: {
      day: '',
      dietId: '',
      productId: '',
    },
    hover: {
      day: '',
      dietId: '',
      productId: '',
    },
    exportPending: false,
    showWeekends: false,
    animation: 0,
  }),
  components: {
    ReportCheckbox,
    DeliveryPlanCell,
    WeekSelect,
    DeliveryPlanImport,
  },
  computed: {
    ...mapGetters('shop', [
      'catalogDetails',
      'products',
    ]),
    updatedCount() {
      return Object.keys(this.updated).filter((x) => this.isUpdated(x)).length;
    },
    columnWidth() {
      return (1 / this.columnCount) * 100;
    },
    columnCount() {
      if (this.diets.length > 1) {
        return 7 + 2;
      }

      return 7 + 1;
    },
    days() {
      if (!this.dates) return [];

      const start = moment(this.dates.from);
      const days = [];

      while (start.isBefore(moment(this.dates.to))) {
        if (this.showWeekends || (start.day() !== 6 && start.day() !== 0)) {
          days.push(start.format());
        }
        start.add(1, 'day');
      }

      return days;
    },
    diets() {
      const { dietId } = this.catalogDetails.metadata;
      return this.catalogDetails.subCatalogNodes.filter((x) => x.subCatalogId === dietId);
    },
    scrollbarWidth() {
      const scrollDiv = document.createElement('div');
      scrollDiv.className = 'scrollbar-measure';
      document.body.appendChild(scrollDiv);

      // Get the scrollbar width
      const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

      // Delete the div
      document.body.removeChild(scrollDiv);
      return scrollbarWidth;
    },
  },
  watch: {
    dates() {
      this.request();
      this.currentDays = this.days;
    },
    showWeekends() {
      this.currentDays = this.days;
    },
    menuNodeId() {
      this.updated = {};
      this.calendar = {};
      this.request();
    },
  },
  methods: {
    ...mapActions('shop', [
      'getDeliveryPlanCalendar',
      'setDeliveryPlanCalendar',
      'generateDeliveryPlan',
      'exportDeliveryPlan',
    ]),
    importData(entries) {
      entries.forEach((entry) => {
        this.$set(this.updated, this.getKey(entry.day, entry.dietId, entry.productId), entry);
      });
    },
    isEmpty(o) {
      return o === null || o === undefined
        || (o.title === '' && o.description === '');
    },
    isUpdated(key) {
      if (this.isEmpty(this.calendar[key])) {
        return !this.isEmpty(this.updated[key]);
      }

      return !!this.updated[key]
      && (this.updated[key].title !== this.calendar[key].title
      || this.updated[key].description !== this.calendar[key].description
      || this.updated[key].tags.join(',') !== this.calendar[key].tags.join(','));
    },
    deleteDay(day) {
      this.diets.forEach((diet) => {
        this.products.forEach((product) => {
          this.$set(this.updated, this.getKey(day, diet.nodeId, product.id), {
            title: '',
            description: '',
            tags: [],
            day,
            dietId: diet.nodeId,
            productId: product.id,
          });
        });
      });
    },
    toggleSelected(cell) {
      if (cell.day === this.selected.day
      && cell.dietId === this.selected.dietId
      && cell.productId === this.selected.productId) {
        return;
      }

      if (this.selected.day) {
        this.selected = {
          day: '',
          dietId: '',
          productId: '',
        };
      } else {
        this.selected = cell;
      }
    },
    dayValue(key) {
      return this.updated[key] || this.calendar[key];
    },
    getKey(day, dietNodeId, productId) {
      if (typeof day === 'object' && day !== null) {
        return `${moment(day.day).format('YYYY-MM-DD')}_${day.dietId}_${day.productId}`;
      }
      return `${moment(day).format('YYYY-MM-DD')}_${dietNodeId}_${productId}`;
    },
    exportMenu() {
      this.exportPending = true;
      this.exportDeliveryPlan({
        params: {
          catalogId: this.catalogId,
          menuNodeId: this.menuNodeId,
          query: {
            from: moment(this.dates.from).format('YYYY-MM-DD'),
            to: moment(this.dates.from).add(1, 'week').format('YYYY-MM-DD'),
            skipWeekends: !this.showWeekends,
          },
        },
      })
        .then(({ data, headers }) => {
          const name = getFileName(headers);
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.exportPending = false;
        });
    },
    save() {
      this.setDeliveryPlanCalendar({
        params: {
          catalogId: this.catalogId,
          menuNodeId: this.menuNodeId,
        },
        data: {
          calendar: Object.keys(this.updated)
            .filter((x) => this.isUpdated(x))
            .map((x) => this.updated[x])
            .map((x) => ({
              dietId: x.dietId,
              productId: x.productId,
              day: moment(x.day).format('YYYY-MM-DD'),
              title: x.title,
              description: x.description,
              tags: x.tags,
            })),
        },
      })
        .then(() => {
          this.updated = {};
          this.request();
        });
    },
    formatWeekDay(date) {
      return moment(date).format('ddd');
    },
    formatDay(date) {
      return moment(date).format('D');
    },
    request() {
      if (!this.dates) return;
      this.pending = true;
      this.calendar = {};
      this.getDeliveryPlanCalendar({
        params: {
          catalogId: this.catalogId,
          menuNodeId: this.menuNodeId,
          query: {
            from: moment(this.dates.from).format('YYYY-MM-DD'),
            to: moment(this.dates.from).add(1, 'week').format('YYYY-MM-DD'),
          },
        },
      })
        .then(({ data }) => {
          data.forEach((d) => {
            this.$set(this.calendar, this.getKey(d.day, d.dietId, d.productId), d);
          });
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.keyDown = () => {
      // TODO: Handle change textarea on arrow click
    };
    window.addEventListener('keydown', this.keyDown);
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown);
  },
};
</script>

<style lang="scss" scoped>
$rowHeight: 70px;
$hover-bg: rgba(#aaa, 0.06);
$highlight-bg: rgba($orange, 0.1);
$border-light: #ececec;
$border-lighter: #eee;
$border-medium: #dcdcdc;
$today-bg: rgba($blue, 0.04);
$today-content-bg: rgba(#fff, 0.03);

.forward-enter-active,
.forward-leave-active,
.back-enter-active,
.back-leave-active {
  transition: all 0.4s ease;
  position: absolute;
  z-index: 0;
}

.day-textarea {
  border: none;
  resize: none;
  width: 100%;
  height: 100%;
  font-weight: 300;
}

.calendar-header {
  border-top: 1px solid #ececec;
  margin-top: 5px;
}

.content-cell {
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;

  textarea {
    border: 1px solid transparent;
  }

  &.updated {
    border-color: $orange;
  }

  &:hover {
    background-color: $hover-bg;
  }

  &.hover {
    background-color: $hover-bg;
  }
  &.highlight {
    background-color: $highlight-bg;
  }
}

.calendar-wrapper {
  overflow: hidden;
  position: relative;
}

.product-row {
  border-bottom: 1px solid $border-lighter;
  height: $rowHeight;
  font-size: 12px;

  &.hover {
    .content-cell {
      background-color: $hover-bg;
    }
  }

  &.highlight {
    .content-cell {
      background-color: $highlight-bg;
    }
  }
}

.product-name {
  font-weight: 500;
  font-size: 0.8rem;
  padding: 10px;
  border-bottom: 1px solid $border-light;
}

.products-wrapper {
  width: 100%;
  position: relative;
  z-index: 2;
}

.diet-name {
  height: $rowHeight;
  font-size: 12px;
  padding: 5px;
  border-bottom: 1px solid $border-lighter;
  font-weight: 400;

  &.hover, &:hover {
    background-color: $hover-bg;
  }

  &.highlight {
    background-color: $highlight-bg;
  }
}

.secondary-icon {
  font-size: 14px;
}

.day-column {
  position: relative;

  .remove-tooltip {
    position: absolute;
    z-index: 100;
    bottom: 0;
    opacity: 0;
    width: 100%;
    padding: 2px;
    text-align: center;
    height: 35px;
    transition: opacity 200ms ease, bottom 200ms ease;
  }

  &:hover {
    .remove-tooltip {
      display: block;
      bottom: -35px;
      opacity: 1;
    }
  }
}

.forward-enter-active,
.forward-leave-active,
.back-enter-active,
.back-leave-active {
  .calendar-header {
    overflow: hidden;
  }
}

.forward-enter,
.back-leave-to {
  transform: translateX(100%);
}

.forward-enter-to,
.back-enter-to {
  transform: translateX(0%);
}

.forward-leave-to,
.back-enter {
  transform: translateX(-100%);
}

.pending {
  opacity: 0.8;
}

.day-header {
  text-align: center;
  position: sticky;
  border-bottom: 1px solid $border-medium;
  padding: 10px;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: uppercase;
  height: 71px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .day-number {
    font-size: 20px;
  }

  &.highlight {
    background-color: $highlight-bg;
  }

  &:hover, &.hover {
    background-color: $hover-bg;
  }
}

.calendar {
  overflow-y: scroll;
  height: calc(100vh - 310px);
  padding-bottom: 20px;
}

.today {
  background-color: $today-bg;

  textarea {
    background-color: $today-content-bg;
  }

  .day-header {
    color: $blue;
  }
}

.day-column {
  border-right: 1px solid $border-medium;
}
</style>
