<template>
  <div
    class="d-flex client-row justify-content-between position-relative client-row-hover"
    :class="{ edited }"
  >
    <div
      class="client-checkbox"
      style="border-right: 1px solid rgba(100, 100, 100, 0.2)"
      data-test="client-select"
      @click="toggleSelect"
    >
      <slot name="select">
        <Checkbox
          :value.sync="selected"
          :emit="false"
          :un-color="edited ? '#fff' : '#dedede'"
          style="position: relative; top: 9px;"
        />
      </slot>
    </div>

    <div
      class="d-flex flex-wrap flex-md-nowrap flex-grow-1"
      @click="select"
    >
      <div
        class="flex-grow-1 pl-2 w-100 client-name"
      >
        <span class="d-inline-block payer-icon">
          <i
            v-if="type === 'Organization'"
            class="fas fa-building pr-2 text-center"
            style="width: 20px"
          />
          <i
            v-else-if="type === 'Person'"
            class="fas fa-person-simple pr-2 text-center"
            style="width: 20px"
          />
        </span>
        <span>{{ name }}</span>
        <span
          v-if="inlineCode && code"
          class="text-secondary small"
        >
          ({{ code }})
        </span>
      </div>
      <div
        v-if="!inlineCode"
        class="text-secondary flex-grow-1 align-self-center client-code small"
      >
        {{ code }}
      </div>

      <slot name="content" />
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/utils/Checkbox';

export default {
  props: {
    id: String,
    name: String,
    code: String,
    type: String,
    isActive: Boolean,
    checked: Boolean,
    payments: Object,
    edited: Boolean,
    disabled: Boolean,
    balance: Array,
    inlineCode: Boolean,
  },
  data: () => ({
    selected: false,
    popover: false,
    pending: false,
    modal: false,
    accCount: '',
    waitingCount: '',
  }),
  components: {
    Checkbox,
  },
  watch: {
    checked(v) {
      this.selected = v;
    },
  },
  methods: {
    toggleSelect(e) {
      if (this.disabled) return;
      e.stopPropagation();
      this.selected = !this.selected;
      this.$emit('toggle', this.selected);
    },
    select(e) {
      if (this.disabled) return;
      e.stopPropagation();
      this.$emit('select');
    },
  },
  created() {
    if (this.checked) {
      this.selected = true;
    }
  },
};
</script>

<style lang="scss" scoped>

  .client-row {
    cursor: pointer;
    font-size: 16px;
    border-bottom: 1px solid #dddddd;

    .edited {
      background-color: rgba(100, 100, 100, 0.2);
    }
  }

  .icon-disabled {
    opacity: 0.2;
    color: #666 !important;

    i {
      color: #666;
    }
  }

  .client-code {
    min-width: 150px;
    padding: 6px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: no-wrap;
  }

  .client-name {
    max-width: 350px;
    min-width: 350px;
    padding: 6px 0;
  }

  .for-verification {
    position: absolute;
    color: white;
    background-color: $blue;
    border-radius: 50%;
    right: -4px;
    top: -4px;
    width: 12px;
    height: 12px;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
  }

  .payer-icon {
    color: $light-gray;
  }

  .client-row-hover:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }

  .client-row {
    .btn.client-btn {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }

  .client-edit {
    i {
      transition: color 300ms, transform 300ms;
      font-size: 12px;
      color: #b8b8b8;
    }

    &:hover i {
      color: #333;
      transform: scale(1.2);
    }
  }

  .action {
    width: 80px;
  }

  .client-details {
    position: absolute;
    width: 100%;
    z-index: 100;
    background-color: white;
  }

  .balance-info {
    padding: 0 10px;
    border-left: 1px solid rgba(100, 100, 100, 0.1);
    border-right: 1px solid rgba(100, 100, 100, 0.1);
    font-weight: 400;

    &.light {
      .balance {
        color: #ddd;
      }
    }

    &.red {
      color: darken($red, 20);
    }

    &.blue {
      color: $blue;
    }
  }

</style>
