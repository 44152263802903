<template>
  <div>
    <div class="font-weight-bold h4">
      {{ $t('shop.deliveryPlan.add') }}
    </div>
    <hr>
    <div class="d-flex">
      <SubCatalogsSidebar
        :catalog="catalogDetails"
        :selected.sync="selected"
        class="text-left"
        select-only
      />

      <div
        v-if="selected.length > 0"
        class="h-100 selection-summary flex-grow-1"
      >
        <div

          class="ml-2"
        >
          <PlanName
            :assignments="selected"
          />
        </div>

        <hr>

        <div class="listings-wrapper">
          <ListingsList
            :listings="filteredListings"
            hide-checkbox
            name-only
          />
        </div>
      </div>
      <div
        v-else
        class="h-100 selection-summary flex-grow-1 small text-center"
      >
        {{ $t('shop.deliveryPlan.selectCategoriesForMenu') }}
      </div>
    </div>

    <hr>
    <div class="d-flex justify-content-end mt-2">
      <div class="px-2">
        <button
          class="btn btn-secondary"
          @click="$emit('close')"
        >
          {{ $t('general.cancel') }}
        </button>
      </div>
      <div class="px-2">
        <button
          class="btn btn-primary"
          @click="save"
        >
          {{ $t('general.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PlanName from '@/components/shop/delivery-plan/PlanName';
import ListingsList from '@/pages/shop/catalogs/listing/ListingsList';
import SubCatalogsSidebar from '@/pages/shop/catalogs/SubCatalogsSidebar';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    catalogId: String,
    deliveryPlan: Object,
  },
  data: () => ({
    selected: [],
    pending: false,
  }),
  components: {
    SubCatalogsSidebar,
    ListingsList,
    PlanName,
  },
  computed: {
    ...mapGetters('shop', [
      'listings',
      'catalogDetails',
    ]),
    filteredListings() {
      return this.listings
        .filter((x) => this.selected.every(({ nodeId }) => x.assignments.some((n) => n.nodeId === nodeId)));
    },
    filteredProducts() {
      return this.filteredListings
        .map((x) => x.productIds);
    },
  },
  methods: {
    ...mapActions('shop', [
      'createDeliveryPlan',
    ]),
    save() {
      this.pending = true;
      this.createDeliveryPlan({
        params: {
          catalogId: this.catalogId,
        },
        data: {
          assignments: this.selected,
        },
      })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.selection-summary {
  border-left: 1px solid rgb(239, 239, 239);
  min-height: 500px;
}

.listings-wrapper {
  max-width: 500px;
}
</style>
