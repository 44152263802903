<template>
  <div
    class="d-flex client-row justify-content-between position-relative"
    :class="{
      edited,
      'client-row-hover': hideDetails,
    }"
  >
    <div
      class="client-checkbox"
      :class="{ 'client-row-hover': !hideDetails }"
      style="border-right: 1px solid rgba(100, 100, 100, 0.2)"
      data-test="client-select"
      @click="toggleSelect"
    >
      <slot name="select">
        <Checkbox
          :value.sync="selected"
          :emit="false"
          :un-color="edited ? '#fff' : '#dedede'"
          style="position: relative; top: 9px;"
        />
      </slot>
    </div>

    <div
      class="d-flex flex-wrap flex-md-nowrap flex-grow-1"
      :class="{ 'client-row-hover': !hideDetails }"
      @click="select"
    >
      <template v-for="c in layout">
        <div
          v-if="c.key === 'name'"
          :key="c.key"
          class="flex-grow-1 pl-2 w-100 client-name"
        >
          <span class="d-inline-block payer-icon">
            <i
              v-if="type === 'Organization'"
              class="fas fa-building pr-2 text-center"
              style="width: 20px"
            />
            <i
              v-else-if="type === 'Person'"
              class="fas fa-person-simple pr-2 text-center"
              style="width: 20px"
            />
          </span>
          <span>{{ name }}</span>
        </div>
        <div
          v-if="c.key === 'clientCode' || c.key === 'code'"
          :key="c.key"
          v-tippy="{
            trigger: (code || '').length > 15 ? 'mouseenter' : 'manual',
            delay: 500
          }"
          :content="code"
          class="text-secondary align-self-center client-code small px-2 text-truncate"
        >
          {{ code }}
        </div>
        <div
          v-if="c.type === 'property'"
          :key="c.key"
          v-tippy="{
            trigger: (properties[c.key] || '').length > 15 ? 'mouseenter' : 'manual',
            delay: 500
          }"
          :content="properties[c.key]"
          class="align-self-center client-code small px-2 text-truncate"
        >
          <PropertyValue
            :code="c.key"
            :value="properties[c.key]"
          />
        </div>
        <div
          v-if="c.type === 'group'"
          :key="c.key"
          class="align-self-center client-code px-2 text-truncate text-primary balance-info"
        >
          <i
            v-if="groups.includes(c.key)"
            class="fas fa-square-check"
          />
          <span v-else>&nbsp;</span>
        </div>
        <div
          v-if="c.key === 'space'"
          :key="c.key"
          class="flex-grow-1 align-self-center"
        >
          &nbsp;
        </div>

        <div
          v-if="(c.key === 'categories' || c.type === 'category') && balance && !hideDetails"
          :key="c.key"
          class="d-flex"
        >
          <div class="d-flex align-items-center position-relative flex-grow-1">
            <div
              class="text-left flex-grow-1 d-flex"
            >
              <div
                v-for="b in balance.filter(y => c.key === 'categories' || y.categoryId === c.key)"
                :key="`${b.categoryId}_${b.currency}`"
                style="width: 150px"
                class="d-flex align-items-center justify-content-between text-right balance-info"
                :class="{
                  light: b.balance === 0,
                  red: b.balance < 0,
                  blue: b.balance > 0,
                }"
              >
                <CategoryInfo
                  :category-id="b.categoryId"
                  color-only
                />
                <span
                  class="money-font text-nowrap small balance"
                >
                  <span v-if="b.balance > 0">+</span>{{ formatCurrency(b.balance, b.currency) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CategoryInfo from '@/components/CategoryInfo';
import Checkbox from '@/components/utils/Checkbox';
import { mapGetters } from 'vuex';
import PropertyValue from './PropertyValue';

export default {
  props: {
    id: String,
    name: String,
    code: String,
    number: Number,
    type: String,
    checked: Boolean,
    edited: Boolean,
    disabled: Boolean,
    hideDetails: Boolean,
    balance: Array,
    properties: Object,
    groups: Array,
    layout: Array,
  },
  data: () => ({
    selected: false,
    popover: false,
    pending: false,
    modal: false,
    accCount: '',
    waitingCount: '',
  }),
  components: {
    PropertyValue,
    CategoryInfo,
    Checkbox,
  },
  computed: {
    ...mapGetters(['formatCurrency']),
  },
  watch: {
    selected(v) {
      this.$emit('toggle', v);
    },
    checked(v) {
      this.selected = v;
    },
  },
  methods: {
    toggleSelect(e) {
      if (this.disabled) return;
      e.stopPropagation();
      this.selected = !this.selected;
    },
    select(e) {
      if (this.disabled) return;
      e.stopPropagation();
      this.$emit('select');
    },
  },
  created() {
    if (this.checked) {
      this.selected = true;
    }
  },
};
</script>

<style lang="scss" scoped>

 .client-row-hover:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }

  .client-row {
    cursor: pointer;
    font-size: 16px;
    border-bottom: 1px solid #dddddd;

    .edited {
      background-color: rgba($blue, 0.2);
    }
  }

  .icon-disabled {
    opacity: 0.2;
    color: #666 !important;

    i {
      color: #666;
    }
  }

  .client-code {
    min-width: 150px;
    max-width: 150px;
    padding: 6px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: no-wrap;
  }

  .client-name {
    max-width: 350px;
    min-width: 250px;
    padding: 6px 0;
  }

  .for-verification {
    position: absolute;
    color: white;
    background-color: $blue;
    border-radius: 50%;
    right: -4px;
    top: -4px;
    width: 12px;
    height: 12px;
    font-size: 9px;
    font-weight: bold;
    text-align: center;
  }

  .payer-icon {
    color: $light-gray;
  }

  .client-row {
    .btn.client-btn {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }

  .client-edit {
    i {
      transition: color 300ms, transform 300ms;
      font-size: 12px;
      color: #b8b8b8;
    }

    &:hover i {
      color: #333;
      transform: scale(1.2);
    }
  }

  .action {
    width: 80px;
  }

  .client-details {
    position: absolute;
    width: 100%;
    z-index: 100;
    background-color: white;
  }

  .balance-info {
    padding: 0 10px;
    border-left: 1px solid rgba(100, 100, 100, 0.1);
    border-right: 1px solid rgba(100, 100, 100, 0.1);
    font-weight: 400;

    &.light {
      .balance {
        color: #ddd;
      }
    }

    &.red {
      color: darken($red, 20);
    }

    &.blue {
      color: $blue;
    }
  }

</style>
