<template>
  <div>
    <div
      v-if="!audit"
    >
      <div
        class="d-flex align-items-center flex-wrap"
      >
        <GroupOrdersDropdown
          class="mr-3 mb-1"
          :group-key.sync="groupKey"
        />
        <CalendarDropdown
          v-if="!singleOrderSchema"
          :highlighted.sync="highlighted"
          :available-days="availableDays"
          :day-status="dayStatus"
          :dates.sync="dates"
          class="mr-3 mb-1"
          @title="selectTitle = $event"
        />
        <PayersFilterDropdown
          class="mr-3 mb-1"
          @selected="selectedPayers = $event"
        />
        <div
          v-if="!pendingDownload"
          v-tippy
          class="secondary-icon ml-3"
          :content="$t('shop.downloadReport')"
          @click="download"
        >
          <i class="fas fa-download" />
        </div>
        <div v-else>
          <Loader />
        </div>
        <div
          v-tippy
          class="secondary-icon smaller-icon ml-2"
          :content="$t('bill.changeHistory')"
          @click="audit = true"
        >
          <i class="fas fa-clock-rotate-left" />
        </div>
        <div
          class="flex-grow-1 d-flex align-items-center justify-content-end"
        >
          <div>
            <button
              class="btn btn-sm btn-primary"
              data-test="toggle-add-client"
              @click="showOrderForm"
            >
              <i class="fas fa-plus pr-1" />
              {{ $t('shop.addOrders') }}
            </button>
          </div>
        </div>
      </div>
      <hr class="my-2">
      <div class="d-flex mb-1">
        <ReportCheckbox
          v-model="showUnpaid"
          class="mr-3"
          :text="$t('shop.showUnpaid')"
        />

        <OrderStatusDropdown
          :statuses="catalogDetails.orderStatuses"
          class="mr-3"
          :selected.sync="selectedOrderStatus"
          @edit="orderStatusForm = true"
        />
        <MonthDropdown
          :title="$t('shop.orderedDate')"
          class="mr-3"
          small
          :selected.sync="ordered"
        />
        <LayoutConfig
          :layout="[]"
          class="mr-3"
        />
      </div>
    </div>

    <BModal
      v-model="orderStatusForm"
      hide-footer
      size="xl"
      hide-header
    >
      <EditOrderStatuses
        :catalog-id="catalogDetails.id"
        :statuses="catalogDetails.orderStatuses"
        @close="orderStatusForm = false"
      />
    </BModal>

    <BModal
      v-model="addingOrder"
      hide-footer
      size="xl"
      hide-header
    >
      <AddOrderForm
        v-if="addingOrder"
        :product-sets="productSets"
        @close="addingOrder = false"
        @refresh="request"
      />
    </BModal>

    <ColumnEdit
      :edit="!!edited"
      :focused="false"
      class="h-100"
    >
      <template v-if="!audit">
        <OrdersList
          v-if="!pending"
          class="flex-grow-1"
          :dates="dates"
          :layout="layout"
          :orders="ordersInSelectedDays"
          :group-by="groupKey"
          :payers="orders.payers"
          :edited-id="edited ? edited.id : null"
          :product-sets="orders.productSets"
          @refresh="request"
          @select="selectOrder"
        />
        <div
          v-else
          class="mt-4"
        >
          <Loader />
        </div>
      </template>

      <OrdersAudit
        v-else
        :catalog-id="catalogDetails.id"
        @close="audit = false"
      />
      <template #edit>
        <div
          v-if="edited"
          class="page-cart"
        >
          {{ edited }}
        </div>
      </template>
    </ColumnEdit>
  </div>
</template>

<script>
import ColumnEdit from '@/components/layout/ColumnEdit';
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import ReportCheckbox from '@/components/reports/ReportCheckbox';
import OrdersAudit from '@/components/shop/audit/OrdersAudit';
import EditOrderStatuses from '@/components/shop/catalog/EditOrderStatuses';
import MonthDropdown from '@/components/shop/MonthDropdown';
import AddOrderForm from '@/components/shop/report/AddOrderForm';
import CalendarDropdown from '@/components/shop/report/CalendarDropdown';
import GroupOrdersDropdown from '@/components/shop/report/GroupOrdersDropdown';
import OrderStatusDropdown from '@/components/shop/report/table//OrderStatusDropdown';
import LayoutConfig from '@/components/shop/report/table/LayoutConfig';
import OrdersList from '@/components/shop/report/table/OrdersList';
import getFileName from '@/utils/file-name';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

const showUnpaidStorageKey = 'shop_showUnpaid';
const groupByStorageKey = 'shop_groupBy';

export default {
  data: () => ({
    dates: null,
    audit: false,
    focus: false,
    orders: {
      orders: [],
      productSets: [],
    },
    addingOrder: false,
    selectTitle: '',
    showUnpaid: true,
    groupKey: 'payers',
    highlighted: [],
    productSets: [],
    selectedPayers: [],
    pendingDownload: false,
    pending: false,
    previousFrom: null,
    haveLayout: false,
    layout: [],
    edited: null,
    selected: null,
    ordered: null,
    selectedOrderStatus: null,
    orderStatusForm: false,
  }),
  components: {
    OrdersList,
    AddOrderForm,
    ColumnEdit,
    CalendarDropdown,
    ReportCheckbox,
    LayoutConfig,
    EditOrderStatuses,
    MonthDropdown,
    // SwitchWithDescription,
    // BalanceReportPayerFieldsModal,
    // OrdersPageSettingModal,
    GroupOrdersDropdown,
    PayersFilterDropdown,
    OrderStatusDropdown,
    OrdersAudit,
  },
  computed: {
    ...mapGetters(['payeeId', 'payee']),
    ...mapGetters('shop', [
      'catalogDetails',
      'singleOrderSchema',
    ]),
    availableDays() {
      return Object.keys(this.dayStatus);
    },
    filteredOrders() {
      return this.orders.orders
        .filter((x) => this.showUnpaid || x.isPaid)
        .filter((x) => this.selectedPayers.length === 0 || this.selectedPayers.some((p) => p.id === x.payerId));
    },
    ordersInSelectedDays() {
      return this.filteredOrders
        .filter((x) => this.highlighted.length === 0 || this.highlighted.includes(x.day));
    },
    dayStatus() {
      return this.filteredOrders.reduce((acc, curr) => {
        if (!acc[curr.day]) {
          acc[curr.day] = {
            paid: 0,
            waiting: 0,
          };
        }

        if (curr.isPaid) {
          acc[curr.day].paid += 1;
        } else {
          acc[curr.day].waiting += 1;
        }
        return acc;
      }, {});
    },
    everyday() {
      if (!this.dates) return [];
      const curr = moment(this.dates.from);
      const end = moment(this.dates.to);
      const result = [];

      while (curr.isBefore(end)) {
        result.push(curr.format('YYYY-MM-DD'));
        curr.add(1, 'day');
      }

      return result;
    },
    reportDays() {
      return this.highlighted.length === 0
        ? this.everyday
        : this.highlighted;
    },
  },
  watch: {
    dates() {
      if (this.debounceRequest) {
        clearTimeout(this.debounceRequest);
      }

      this.debounceRequest = setTimeout(() => {
        this.request();
      }, 300);
    },
    showUnpaid(v) {
      if (v) {
        localStorage.setItem(showUnpaidStorageKey, v);
      } else {
        localStorage.removeItem(showUnpaidStorageKey);
      }
    },
    groupKey(v) {
      localStorage.setItem(groupByStorageKey, v);
    },
  },
  methods: {
    ...mapActions('shop', [
      'getOrders',
      'getOrdersReport',
    ]),
    showOrderForm() {
      if (this.catalogDetails.id === '00000000-0000-0000-0000-000000000000') {
        this.addingOrder = true;
      } else {
        this.$router.push(`/payee/${this.payeeId}/shop/${this.catalogDetails.id}/order`);
      }
    },
    selectOrder(o) {
      if (this.edited && this.edited.id === o.id) {
        this.edited = null;
      } else {
        this.edited = o;
      }
    },
    initLayout() {
      this.layout = [
        { key: 'name', type: 'default' },
        { key: 'code', type: 'default' },
        // { key: 'day', type: 'default' },
        { key: 'productSetName', type: 'default' },
        { key: 'space', type: 'default' },
        { key: 'paid', type: 'default' },
        { key: 'created', type: 'default' },
        { key: 'delivered', type: 'default' },
        { key: 'comment', type: 'default' },
        { key: 'count', type: 'default' },
        { key: 'price', type: 'default' },
      ];

      try {
        if (this.catalogDetails.ordersLayout) {
          this.layout = JSON.parse(this.catalogDetails.ordersLayout);
        }
      } catch {
        //
      }

      this.haveLayout = true;
      this.request();
    },
    refreshLayout() {
      this.initLayout();
      this.refresh();
    },
    download() {
      this.pendingDownload = true;

      this.getOrdersReport({
        data: {
          catalogId: this.catalogDetails.id,
          days: this.reportDays,
          showUnpaid: this.showUnpaid,
          payerIds: this.selectedPayers.map((x) => x.id),
          properties: ['name', 'code'],
        },
      })
        .then(({ data, headers }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', getFileName(headers));
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.pendingDownload = false;
        });
    },
    request() {
      if (!this.haveLayout) return;
      if (this.singleOrderSchema) {
        this.pending = true;
        this.getOrders({
          params: {
            query: {
              catalogId: this.catalogDetails.id,
            },
          },
        })
          .then(({ data }) => {
            this.orders = data;
          })
          .finally(() => {
            this.pending = false;
          });

        return;
      }

      if (!this.dates) return;

      this.pending = true;
      this.getOrders({
        params: {
          query: {
            catalogId: this.catalogDetails.id,
            from: this.dates.from,
            to: this.dates.to,
          },
        },
      })
        .then(({ data, config }) => {
          if (this.dates.from === config.params.query.from) {
            this.orders = data;
          }
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.showUnpaid = !!localStorage.getItem(showUnpaidStorageKey);
    this.groupKey = localStorage.getItem(groupByStorageKey) || 'payers';

    this.initLayout();

    this.$emit('page', 'orders');
  },
};
</script>

<style>
.smaller-icon {
    font-size: 0.85rem;
  }
</style>
