<template>
  <div class="sub-catalog-sidebar mr-2">
    <BModal
      v-model="subCatalogForm"
      hide-footer
      hide-header
      :size="editedSubCatalog ? 'lg' : 'md'"
    >
      <div
        class="d-flex justify-content-between"
      >
        <div
          class="font-weight-bold"
          style="font-size: 20px"
        >
          {{ editedSubCatalog ? editedSubCatalog.name : $t('shop.subcatalog.add') }}
        </div>
        <div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="subCatalogForm = false"
          >
            ×
          </button>
        </div>
      </div>
      <hr
        v-if="editedSubCatalog"
        class="mt-1 mb-2"
      >
      <ProductsEditor
        v-if="editedSubCatalog && editedSubCatalog.subCatalogId === '#products'"
        :catalog-id="catalog.id"
        @refresh="$emit('refresh')"
      />
      <SubCatalogEditor
        v-else-if="editedSubCatalog"
        :sub-catalog="editedSubCatalog"
        :read-only-nodes="editedSubCatalog.subCatalogId.startsWith('#')"
        :nodes="catalog.subCatalogNodes.filter(x => x.subCatalogId === editedSubCatalog.subCatalogId)"
        :catalog-id="catalog.id"
        @close="subCatalogForm = false"
      />
      <SubCatalogEditor
        v-else
        :catalog-id="catalog.id"
        @close="subCatalogForm = false"
      />
    </BModal>

    <Draggable
      v-model="orderedSubCatalogs"
      class="draggable"
      ghost-class="ghost"
      handle=".handle"
      animation="200"
      :class="{ moving }"
      @start="moving = true"
      @end="moving = false"
    >
      <TransitionGroup
        type="transition"
        :name="!moving ? 'flip-list' : null"
      >
        <div
          v-for="s in orderedSubCatalogs"
          :key="s.subCatalogId"
          class="sub-catalog-box w-100"
          :class="{ single: !!onlySubCatalogId }"
        >
          <div class="d-flex justify-content-between mb-2">
            <div class="font-weight-bold">
              <i
                v-if="!selectOnly"
                class="fas fa-grip-dots-vertical handle"
              />
              <i
                v-if="s.subCatalogId === '#products'"
                class="far fa-box handle ml-2"
              />
              <i
                v-if="s.subCatalogId === '#groups'"
                class="far fa-users handle ml-2"
              />
              {{ s.name }}
            </div>
            <div class="d-flex">
              <div
                v-if="s.layoutRole > 0 && !selectOnly"
                v-tippy
                class="secondary-icon secondary-icon-sm mr-1 font-weight-bold"
                :content="layoutOptions.find(x => x.value === s.layoutRole)?.text"
              >
                {{ s.layoutRole }}
              </div>
              <div
                v-if="!selectOnly"
                class="secondary-icon secondary-icon-sm"
                @click="editedSubCatalog = s; subCatalogForm = true"
              >
                <i class="fas fa-pen" />
              </div>
            </div>
          </div>
          <SubCatalogTree
            node-id=""
            :sub-catalog-id="s.subCatalogId"
            :selected="selectedNodes.map(x => x.nodeId)"
            :nodes="s.nodes"
            @select="toggleNode($event)"
          />
        </div>
      </TransitionGroup>
    </Draggable>

    <div
      v-if="orderSaved"
      class="text-success mt-2 small text-center"
    >
      {{ $t('shop.orderChangeSaved') }}
    </div>

    <div
      v-if="!selectOnly"
      class="d-flex justify-content-center py-3"
    >
      <div class="pl-2">
        <div
          class="secondary-icon bill-button px-3"
          @click="editedSubCatalog = null; subCatalogForm = true"
        >
          <i class="fas fa-plus mr-2" />
          <span class="edit-details">
            {{ $t('shop.subcatalog.add') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import { mapActions, mapGetters } from 'vuex';
import ProductsEditor from './sub-catalog/ProductsEditor';
import SubCatalogEditor from './sub-catalog/SubCatalogEditor';
import SubCatalogTree from './SubCatalogTree';

export default {
  props: {
    catalog: Object,
    selected: Array,
    onlySubCatalogId: String,
    selectOnly: Boolean,
  },
  data: () => ({
    editedSubCatalog: null,
    subCatalogForm: false,
    selectedNodes: [],
    moving: false,
    order: {},
    orderPending: false,
    orderSaved: false,
  }),
  components: {
    Draggable,
    SubCatalogTree,
    SubCatalogEditor,
    ProductsEditor,
  },
  computed: {
    ...mapGetters(['groups']),
    ...mapGetters('shop', ['layoutOptions']),
    orderedSubCatalogs: {
      get() {
        return this.catalog.subCatalogs
          .filter((x) => !this.onlySubCatalogId || this.onlySubCatalogId === x.subCatalogId)
          .map((x) => ({
            ...x,
            nodes: this.catalog.subCatalogNodes.filter((s) => x.subCatalogId === s.subCatalogId),
          }))
          .sort((a, b) => this.order[a.subCatalogId] - this.order[b.subCatalogId]);
      },
      set(v) {
        this.order = {};
        v.forEach((x, i) => {
          this.$set(this.order, x.subCatalogId, i);
        });
      },
    },
    orderModified() {
      return this.catalog.subCatalogs
        .some((x) => this.order[x.subCatalogId] !== x.ordinal);
    },
  },
  watch: {
    selected(s) {
      this.selectedNodes = s;
    },
    orderModified(modified) {
      if (modified) {
        this.saveOrder();
      }
    },
  },
  methods: {
    ...mapActions('shop', [
      'updateShopSubCatalogsOrder',
    ]),
    toggleNode({ nodeId, subCatalogId }) {
      if (this.selectedNodes.some((x) => x.nodeId === nodeId)) {
        this.selectedNodes = this.selectedNodes.filter((x) => x.nodeId !== nodeId);
      } else {
        this.selectedNodes.push({ subCatalogId, nodeId });
      }

      this.$emit('update:selected', this.selectedNodes);
    },
    saveOrder() {
      if (this.orderPending) return;

      this.orderPending = true;
      this.updateShopSubCatalogsOrder({
        params: {
          catalogId: this.catalog.id,
        },
        data: {
          orderedIds: Object.entries(this.order)
            .sort((a, b) => a[1] - b[1])
            .map((x) => x[0]),
        },
      })
        .finally(() => {
          this.orderPending = false;
          this.orderSaved = true;

          setTimeout(() => {
            this.orderSaved = false;
          }, 2000);
        });
    },
    cancelOrder() {
      this.order = {};
      this.catalog.subCatalogs.forEach((x) => {
        this.order[x.subCatalogId] = x.ordinal;
      });
    },
  },
  created() {
    this.selectedNodes = this.selected;
    this.cancelOrder();
  },
};
</script>

<style lang="scss" scoped>
.sub-catalog-sidebar {
  width: 300px;
  overflow: auto;
}

.animation-container {
  position: relative;
}

.close {
  font-size: 35px;
  position: relative;
  top: -5px;
  right: 5px;
}

.micro-button {
  border-radius: 20px;
  width: auto;
  font-size: 14px;
  padding: 2px 15px;
  margin-left: 10px;
  background-color: #aaa;
  color: #fff;

  i {
    margin-right: 8px;
    font-size: 12px;
  }

  &.micro-button-primary {
    background-color: $blue;
    color: #fff;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.handle {
  cursor: move;
  cursor: grab;
  margin-right: 5px;
  color: #aaa;
}

.draggable::v-deep(.ghost) {
  cursor: grabbing;
  background-color: white;
  border-bottom: none;
}

.sub-catalog-box {
  padding: 1em 0.6em;
  border-bottom: 1px solid #efefef;
  background-color: #fff;

  &.single {
    padding: 0 0.6em;
    border-bottom: none;
  }
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
