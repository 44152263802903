<template>
  <div v-if="!edit && listing">
    <div class="d-flex justify-content-between pt-4">
      <div class="product-name">
        {{ listing.name }}
      </div>
      <div class="d-flex">
        <div
          v-if="listing"
          class="mr-3"
        >
          <Tippy
            interactive
            arrow
            theme="light"
            trigger="click"
          >
            <template #trigger>
              <i
                v-tippy
                class="fas fa-trash secondary-icon"
                :content="$t('general.remove')"
              />
            </template>

            <div v-if="!pendingRemove">
              <button
                class="btn btn-block btn-danger"
                @click="remove"
              >
                <i class="fas fa-trash pr-2" />
                {{ $t('general.confirmRemove') }}
              </button>
            </div>
            <div v-else>
              <Loader size="22px" />
            </div>
          </Tippy>
        </div>
        <div>
          <i
            v-tippy
            class="fas fa-pen secondary-icon smaller-icon"
            :content="$t('general.edit')"
            @click="edit = true"
          />
        </div>
      </div>
    </div>

    <div
      v-if="listing.description"
      class="small text-secondary"
      style="white-space: pre-wrap;"
    >
      {{ listing.description }}
    </div>
    <hr>
    <div class="d-flex justify-content-between align-items-center mt-4 ">
      <div class="section-title">
        {{ $t('shop.price') }}
      </div>

      <div class="money-font">
        {{ formatCurrency(listing.price, listing.currency) }}
      </div>
    </div>
    <hr>

    <template v-if="listing.productIds.length > 0">
      <div class="section-title mt-4 mb-3">
        {{ $t('shop.products') }}
      </div>
      <div class="group-price px-2 first">
        <div
          v-for="productId in listing.productIds"
          :key="productId"
        >
          <span>
            {{ productName(productId) }}
          </span>
        </div>
      </div>
    </template>

    <div
      v-for="subCatalog in subCatalogsSorted"
      :key="subCatalog.id"
    >
      <div class="section-title mt-3 mb-2">
        {{ subCatalog.name }}
      </div>
      <div class="group-price px-2 first">
        <div v-if="subCatalog.nodes.length === 0">
          -
        </div>
        <div
          v-for="node in subCatalog.nodes"
          :key="node.nodeId"
          class="d-flex justify-content-between"
        >
          {{ node.name }}
        </div>
      </div>
    </div>

    <div
      v-if="timeLimits.length > 0 || listing.countLimit >= 0"
      class="section-title mt-4 mb-3"
    >
      {{ $t('shop.timeBoundaries') }}
    </div>
    <div
      v-for="(limit, i) in timeLimits"
      :key="limit.label"
      class="d-flex justify-content-between group-price px-2"
      :class="{ first: i === 0 }"
    >
      <div class="text-secondary font-weight-bold">
        <i
          :class="timeIcon(limit.time)"
          class="pr-2"
        />
        {{ limit.label }}
      </div>
      <div>
        <span
          v-if="singleOrderSchema"
          class="small"
        >
          {{ formatTime(limit.time) }}
        </span>
        <TimeReferenceBadge
          v-else
          :value="limit.time"
          details
        />
      </div>
    </div>
    <div
      v-if="listing.countLimit >= 0"
      class="d-flex justify-content-between group-price px-2"
      :class="{ first: timeLimits.length === 0 }"
    >
      <div class="text-secondary font-weight-bold">
        <i class="fa-solid fa-circle-1 pr-2" />
        {{ $t('shop.listing.limitCount') }}
      </div>
      <div>
        <span
          class="small"
        >
          {{ listing.countLimit }}
        </span>
      </div>
    </div>
  </div>
  <ProductListingForm
    v-else
    :listing="listing"
    @close="closeForm"
  />
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import TimeReferenceBadge from '../TimeReferenceBadge';
import ProductListingForm from './ProductListingForm';

export default {
  props: {
    listing: Object,
  },
  data: () => ({
    edit: false,
    pendingRemove: false,
  }),
  components: {
    ProductListingForm,
    TimeReferenceBadge,
  },
  computed: {
    ...mapGetters(['formatCurrency', 'groups']),
    ...mapGetters('shop', [
      'products',
      'catalogDetails',
      'singleOrderSchema',
    ]),
    timeLimits() {
      return [
        {
          time: this.listing.orderFrom,
          label: this.$t('shop.orderFrom'),
        },
        {
          time: this.listing.orderTo,
          label: this.$t('shop.orderTo'),
        },
        {
          time: this.listing.cancelTo,
          label: this.$t('shop.cancelTo'),
        },
      ].filter((x) => x.time);
    },
    subCatalogsSorted() {
      return this.catalogDetails.subCatalogs
        .slice()
        .filter(({ subCatalogId }) => subCatalogId !== '#products')
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((x) => ({
          ...x,
          nodes: this.listing.assignments
            .filter(({ subCatalogId }) => subCatalogId === x.subCatalogId)
            .map(({ subCatalogId, nodeId }) => {
              if (subCatalogId === '#groups') {
                const group = this.groups.find((g) => g.code === nodeId);
                if (!group) return null;
                return {
                  nodeId: group.code,
                  name: group.code === '#all' ? this.$t('company.all') : group.path,
                };
              }

              return this.catalogDetails.subCatalogNodes
                .filter((n) => n.subCatalogId === x.subCatalogId)
                .find((s) => s.nodeId === nodeId);
            })
            .filter((n) => n)
            .sort((a, b) => {
              if (x.subCatalogId === '#groups') {
                return a.name.localeCompare(b.name);
              }

              return a.ordinal - b.ordinal;
            }),
        }));
    },
    sortedAssignments() {
      return this.listing.assignments
        .map(({ subCatalogId, nodeId }) => {
          if (subCatalogId === '#groups') {
            const group = this.groups.find((g) => g.code === nodeId);
            return {
              nodeId: group.code,
              nodeName: group.path,
            };
          }

          const node = this.catalogDetails.subCatalogNodes.find((s) => s.nodeId === nodeId);
          return {
            nodeId: node?.nodeId,
            nodeName: node?.name,
          };
        })
        .filter((x) => x.nodeId);
    },
  },
  watch: {
    listing() {
      this.edit = false;
    },
  },
  methods: {
    ...mapActions('shop', [
      'deleteCatalogListing',
    ]),
    remove() {
      this.pendingRemove = true;

      this.deleteCatalogListing({
        params: {
          catalogId: this.catalogDetails.id,
          listingId: this.listing.id,
        },
      })
        .finally(() => {
          this.pendingRemove = false;
        });
    },
    closeForm() {
      this.edit = false;
      if (!this.listing) {
        this.$emit('close');
      }
    },
    formatTime(date) {
      return moment(date, 'YYYY-MM-DD HH:mm').format('DD MMMM YYYY HH:mm');
    },
    productName(productId) {
      return this.products.find((x) => x.id === productId)?.name;
    },
    subCatalogName(id) {
      return this.catalogDetails.subCatalogNodes.find((x) => x.subCatalogId === id)?.name;
    },
    subCatalogNodeName(id) {
      return this.catalogDetails.subCatalogs.find((x) => x.subCatalogId === id)?.name;
    },
    timeIcon() {
      return 'fas fa-calendar';
    },
  },
};
</script>

<style lang="scss" scoped>
.product-name {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}

.assignment-badge {
  color: #fff;
  background-color: rgba($blue, 0.4);
}

.group-price {
  padding: 5px;
  border: 1px solid #eee;
  border-top: none;

  &.first {
    border-top: 1px solid #eee;
  }
}

.section-title {
  font-size: 16px;
  font-weight: 500;
}
</style>
