<template>
  <Tippy
    ref="popover"
    trigger="click"
    interactive
    theme="light"
    max-width="600"
    placement="bottom"
  >
    <template #trigger>
      <div
        class="filter-header"
      >
        {{ $t('shop.orderStatus') }}
      </div>

      <div
        class="sort-option-selected justify-content-center align-items-center"
      >
        <div class="text-center h-100 pr-2">
          <i
            class="far fa-box-circle-check text-secondary"
            style="font-size: 18px"
          />
        </div>
        <div v-if="selectedStatus">
          <span
            class="badge px-3"
            :style="{ backgroundColor: colors(selectedStatus.color) }"
          >
            {{ selectedStatus.name }}
          </span>
        </div>
        <div
          v-else
          class="small flex-grow-1"
        >
          -
        </div>
      </div>
    </template>

    <div>
      <div
        class="sort-option"
        @click="select(null)"
      >
        <span class="small">
          {{ $t('shop.status.all') }}
        </span>
      </div>
      <div
        v-for="status in statuses"
        :key="status.id"
        class="sort-option"
        @click="select(status.id)"
      >
        <span
          class="badge px-3"
          :style="{ backgroundColor: colors(status.color) }"
        >
          {{ status.name }}
        </span>
      </div>
    </div>
    <div
      class="sort-option text-left text-secondary font-weight-bold edit-option"
      @click="$emit('edit'); $refs.popover.tippy().hide()"
    >
      <i class="fas fa-pen pr-2" />
      {{ $t('general.edit') }}
    </div>
  </Tippy>
</template>

<script>
import colors from '@/utils/colors';

export default {
  props: {
    selected: String,
    statuses: Array,
  },
  data: () => ({
    modal: false,
  }),
  computed: {
    selectedStatus() {
      return this.statuses.find((x) => x.id === this.selected);
    },
  },
  methods: {
    colors,
    select(id) {
      this.$emit('update:selected', id);
      this.$refs.popover.tippy().hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/components/reports/small-filter.scss';

.badge {
  color: white;
}

.sort-option-selected .badge {
  font-size: 11px;
}

.edit-option {
  font-size: 0.8rem;
}

.page-setting {
  color: #aaa;
  font-size: 1.2rem;
  padding: 0;
  width: 44px;
  text-align: center;
}

.draggable {
  border: 1px solid transparent;
  border-radius: 10px;
  min-height: 50px;
  background-color: #f8f8f8;
  padding: 5px;
  margin-top: 5px;
  min-height: 200px;
  padding-bottom: 40px;
}

.draggable.moving {
  border: 1px solid #aaa;
}

.moving * {
  cursor: grabbing;
}

.remove-button {
  padding-left: 10px;
  color: #aaa;
  cursor: pointer;
}

.column {
  padding: 0.3rem 0.6rem;
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  border: 2px solid #eee;
  margin-top: 6px;
  font-size: 1rem;
  line-height: 1.2;
  cursor: pointer;
  transition: border-color 300ms, margin-right 300ms;

  &:hover {
    border-color: #999;
  }
}
</style>
