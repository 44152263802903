<template>
  <span class="node-names">
    {{ nodeNames.join(' - ') }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    assignments: Array,
  },
  computed: {
    ...mapGetters('shop', [
      'catalogDetails',
    ]),
    nodeNames() {
      return this.catalogDetails.subCatalogs
        .map((sc) => {
          const nodes = this.assignments
            .filter((x) => x.subCatalogId === sc.subCatalogId)
            .map((a) => this.catalogDetails.subCatalogNodes.find((n) => n.nodeId === a.nodeId)?.name);

          return nodes.join('+');
        })
        .filter((x) => x.length > 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.node-names {
  font-weight: 500;
  background-color: #efefef;
  padding: 3px 12px;
  border-radius: 10px;
}
</style>
