<template>
  <div
    class="d-flex align-items-center"
    :class="{ create, update }"
  >
    <div
      v-if="create"
      class="checkbox"
    >
      <i class="fas fa-circle-plus" />
    </div>
    <div
      v-else-if="update"
      class="checkbox"
    >
      <i class="fas fa-edit" />
    </div>
    <div
      v-else-if="!hideCheckbox"
      class="checkbox"
      @click="$emit('update:checked', !checked)"
    >
      <Checkbox
        :value="checked"
        class="px-0"
      />
    </div>
    <div
      v-else
      class="checkbox"
    />
    <div
      class="listing-row"
      :class="{ selected }"
      @click="$emit('select')"
    >
      <div class="d-flex align-items-center row-content">
        <div class="font-weight-bold listing-name">
          {{ listing.name }}
        </div>
        <div
          class="text-left"
          style="min-width: 200px"
        >
          <div
            v-for="a in listingProducts"
            :key="a.id"
            class="badge product-badge px-2 mr-1"
          >
            {{ a.name }}
          </div>
        </div>
        <div
          v-for="s in subCatalogsSorted"
          :key="s.subCatalogId"
          v-tippy
          class="flex-grow-1 small listing-nodes"
          :content="s.nodeNames.join('<br>')"
        >
          <span v-if="s.nodes.length === 0">-</span>
          <span v-else>
            {{ s.nodeNames.join(', ') }}
          </span>
        </div>
        <div
          v-if="!nameOnly"
          class="time-column"
        >
          {{ listing.countLimit < 0 ? '-' : listing.countLimit }}
        </div>
        <div
          v-if="!nameOnly"
          class="time-column"
        >
          <TimeReferenceBadge :value="listing.orderFrom" />
        </div>
        <div
          v-if="!nameOnly"
          class="time-column"
        >
          <TimeReferenceBadge :value="listing.orderTo" />
        </div>
        <div
          v-if="!nameOnly"
          class="time-column"
        >
          <TimeReferenceBadge :value="listing.cancelTo" />
        </div>
        <div
          v-if="!nameOnly"
          class="money-font text-right pr-1 money-column"
        >
          {{ formatCurrency(listing.price, 'pln') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TimeReferenceBadge from '../TimeReferenceBadge';

export default {
  props: {
    checked: Boolean,
    listing: Object,
    nodes: Array,
    selected: Boolean,
    create: Boolean,
    update: Boolean,
    nameOnly: Boolean,
    hideCheckbox: Boolean,
    updateFields: Array,
  },
  components: {
    TimeReferenceBadge,
  },
  computed: {
    ...mapGetters([
      'formatCurrency',
      'groups',
    ]),
    ...mapGetters('shop', [
      'products',
      'catalogDetails',
    ]),
    subCatalogsSorted() {
      return this.catalogDetails.subCatalogs
        .filter((x) => x.subCatalogId !== '#products')
        .slice()
        .sort((a, b) => a.ordinal - b.ordinal)
        .map((x) => {
          const nodes = this.getSubCatalogNodes(x.subCatalogId);
          return {
            ...x,
            nodes,
            nodeNames: nodes
              .map((n) => n.name)
              .sort((a, b) => a.localeCompare(b)),
          };
        });
    },
    listingProducts() {
      if (!this.products) return [];
      return this.listing.productIds
        .map((id) => this.products.find((p) => p.id === id))
        .filter((x) => x);
    },
    listingNodes() {
      return this.listing.assignments
        .map(({ nodeId, subCatalogId }) => {
          if (subCatalogId === '#groups') {
            const group = this.groups?.find((g) => g.code === nodeId);
            if (!group) return null;
            return {
              ...group,
              subCatalogId: '#groups',
              name: group.code === '#all' ? this.$t('company.all') : group.path,
            };
          }

          return this.catalogDetails.subCatalogNodes.find((n) => n.nodeId === nodeId);
        })
        .filter((x) => x);
    },
  },
  methods: {
    getSubCatalogNodes(subCatalogId) {
      return this.listingNodes.filter((x) => x.subCatalogId === subCatalogId);
    },
  },
};
</script>

<style lang="scss" scoped>

.listing-name {
  font-size: 14px;
  min-width: 300px;
  max-width: 300px;
}

.checkbox {
  background-color: #f9f9f9;
  height: 42px;
  width: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(100, 100, 100, 0.1);
  }
}

.listing-nodes {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-width: 120px;
  max-width: 120px;
  flex-basis: 120px;
  padding-right: 5px;
}

.product-badge {
  color: #fff;
  background-color: rgba($blue, 0.8);
  font-size: 11px;
}

.time-column {
  min-width: 100px;
  font-size: 11px;
  text-align: left;
  display: flex;
}

.row-content {
  height: 42px;
}

.money-column {
  min-width: 120px;
  font-size: 14px
}

.listing-row {
  padding: 0px 5px;
  background-color: #f9f9f9;
  min-width: min-content;
  margin: 5px 0;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }

  &.selected {
    background-color: rgba(0, 112, 255, 0.1);
  }
}

.create {
  .checkbox {
    text-align: center;
    justify-content: center;
    color: $green;
    background-color: rgba($green, 0.2);
    cursor: default;
  }

  .listing-row {
    cursor: default;
    background-color: rgba($green, 0.2);
  }
}

.update {
  .checkbox {
    text-align: center;
    justify-content: center;
    color: $blue;
    background-color: rgba($blue, 0.2);
    cursor: default;
  }

  .listing-row {
    background-color: rgba($blue, 0.2);
    cursor: default;
  }
}

.node-badge {
  color: #333;
  font-size: 11px;
}
</style>
