<template>
  <div v-if="singleOrderSchema">
    {{ formatted(value) }}
  </div>
  <div
    v-else-if="parsed"
    v-tippy
    :content="typeName"
    class="d-flex align-items-center"
  >
    <i
      class="far pr-1"
      :class="icon"
    />
    <div v-if="details">
      <div
        class="small ml-2"
        style="width: 110px"
      >
        <div>
          {{ parsed.hour }}
        </div>
        <div v-if="parsed.dayOffset != 0">
          <span v-if="parsed.dayOffset < 0">{{ parsed.dayOffset }} {{ $t('shop.timeReference.daysBefore') }}</span>
          <span v-if="parsed.dayOffset > 0">{{ parsed.dayOffset }} {{ $t('shop.timeReference.daysAfter') }}</span>
        </div>
        <div v-if="parsed.skipWeekend">
          {{ $t('report.time.skipWeekend') }}
        </div>
      </div>
    </div>
    <template v-else>
      <span
        v-if="parsed.dayOffset != 0"
        class="pr-1"
      >
        ({{ parsed.dayOffset >= 0 ? '+' : '' }}{{ parsed.dayOffset }}d)
      </span>
      <span>{{ parsed.hour }}</span>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    value: String,
    details: Boolean,
  },
  computed: {
    ...mapGetters('shop', [
      'timeReferenceTypes',
      'parseTimeReference',
      'singleOrderSchema',
    ]),
    typeName() {
      return this.timeReferenceTypes.find((x) => x.value === this.parsed?.type)?.text;
    },
    icon() {
      switch (this.parsed?.type) {
        case 'ListingMonthStart':
          return 'fa-arrow-left-to-line';
        case 'ListingMonthEnd':
          return 'fa-arrow-right-to-line';
        case 'ListingDay':
          return 'fa-calendar-day';
        default:
          return '';
      }
    },
    parsed() {
      return this.parseTimeReference(this.value);
    },
  },
  methods: {
    formatted(v) {
      if (!v) {
        return '-';
      }
      const date = moment(v, 'YYYY-MM-DD HH:mm');

      if (date.year() === moment().year()) {
        return date.format('DD MMM HH:mm');
      }
      return date.format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>
