<template>
  <div>
    <div
      class="sort-option-selected page-setting d-flex align-items-center justify-content-center"
      @click="modal = true"
    >
      <i class="fa-solid fa-gears" />
    </div>

    <BModal
      v-model="modal"
      hide-footer
      hide-header
      size="lg"
      lazy
    >
      <PayersPropertiesColumns
        :layout.sync="layout"
        :other-columns="[{
          name: $t('settings.billsCategories'),
          key: 'categories',
        }]"
      />

      <hr>
      <div class="d-flex justify-content-between">
        <div>
          <RemoveButton
            :pending="pending"
            @confirm="remove"
          >
            <i class="fas fa-trash-can-undo remove-button" />
          </RemoveButton>
        </div>
        <div class="d-flex">
          <div class="mx-1">
            <button
              class="btn btn-sm btn-block btn-secondary"
              style="width: 150px"
              :disabled="pending"
              @click="modal = false"
            >
              {{ $t('general.cancel') }}
            </button>
          </div>
          <div class="mx-1">
            <button
              class="btn btn-sm btn-block btn-primary"
              :disabled="pending"
              style="width: 150px"
              @click="save"
            >
              {{ $t('general.save') }}
            </button>
          </div>
        </div>
      </div>
    </BModal>
  </div>
</template>

<script>
import RemoveButton from '@/components/utils/RemoveButton';
import { mapActions, mapGetters } from 'vuex';
import PayersPropertiesColumns from './PayersPropertiesColumns';

const layoutPropertyName = 'PAYERS_LAYOUT';

export default {
  data: () => ({
    modal: false,
    layout: [],
    pending: false,
  }),
  components: {
    PayersPropertiesColumns,
    RemoveButton,
  },
  computed: {
    ...mapGetters([
      'payee',
      'payeeProperty',
      'userGroups',
      'properties',
    ]),
    storedLayout() {
      return this.payeeProperty(layoutPropertyName);
    },
  },
  watch: {
    storedLayout() {
      this.init();
    },
  },
  methods: {
    ...mapActions(['setPayeeProperties']),
    remove() {
      this.pending = true;
      this.setPayeeProperties({
        data: {
          name: layoutPropertyName,
          value: '',
        },
      })
        .finally(() => {
          this.pending = false;
          this.$emit('refresh');
        });
    },
    save() {
      this.pending = true;
      this.setPayeeProperties({
        data: {
          name: layoutPropertyName,
          value: JSON.stringify(this.layout),
        },
      })
        .then(() => {
          this.modal = false;
        })
        .finally(() => {
          this.pending = false;
          this.$emit('refresh');
        });
    },
    init() {
      let layout = [
        { key: 'name', type: 'default' },
        { key: 'code', type: 'default' },
        { key: 'space', type: 'default' },
        { key: 'categories', type: 'default' },
      ];

      try {
        if (this.storedLayout) {
          layout = JSON.parse(this.storedLayout);
        }
      } catch {
      //
      }

      this.layout = layout;
    },
  },
  created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';

.page-setting {
  color: #aaa;
  font-size: 1.2rem;
  padding: 0;
  width: 44px;
  text-align: center;
}

.remove-button {
  padding-left: 10px;
  color: #aaa;
  cursor: pointer;
}
</style>
