<template>
  <div class="header d-flex flex-wrap flex-md-nowrap">
    <div
      v-for="c in columns"
      :key="c.key"
      :class="c.key"
    >
      {{ c.key }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array,
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";

.header {
  color: #666;
  padding-left: 40px;
  margin-top: 5px;
  align-items: center;
  border-bottom: 1px solid #dddddd;

  div {
    font-size: 0.8rem !important;
  }
}
</style>
