<template>
  <div class="d-flex align-items-center ml-2">
    <div
      class="week-select"
      @click="back"
    >
      <i class="fa-solid fa-chevron-left" />
    </div>
    <div
      class="week-select"
      @click="forward"
    >
      <i class="fa-solid fa-chevron-right" />
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div
      v-if="from != todayWeekStart"
      class="today-revert"
      @click="backToToday"
    >
      <i class="fas fa-undo" />
      {{ $t('general.today') }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data: () => ({
    from: moment().startOf('week').format(),
    to: moment().startOf('week').add(1, 'week').format(),
  }),
  computed: {
    todayWeekStart() {
      return moment().startOf('week').format();
    },
    title() {
      if (moment(this.from).month() !== moment(this.to).month()) {
        return `${moment(this.from).format('MMM')} - ${moment(this.to).format('MMM YYYY')}`;
      }

      return moment(this.from).format('MMMM YYYY');
    },
  },
  methods: {
    backToToday() {
      if (moment(this.from).isBefore(moment(this.todayWeekStart))) {
        this.$emit('move', 'forward');
      } else {
        this.$emit('move', 'back');
      }

      this.from = moment().startOf('week').format();
      this.to = moment().startOf('week').add(1, 'week').format();

      this.$emit('dates', {
        from: this.from,
        to: this.to,
      });
    },
    forward() {
      this.from = moment(this.from).add(1, 'week').format();
      this.to = moment(this.from).add(1, 'week').format();
      this.$emit('move', 'forward');

      this.$emit('dates', {
        from: this.from,
        to: this.to,
      });
    },
    back() {
      this.from = moment(this.from).subtract(1, 'week').format();
      this.to = moment(this.from).add(1, 'week').format();

      this.$emit('move', 'back');

      this.$emit('dates', {
        from: this.from,
        to: this.to,
      });
    },
  },
  created() {
    this.$emit('dates', {
      from: this.from,
      to: this.to,
    });
  },
};
</script>

<style lang="scss" scoped>

.title {
  font-weight: 500;
  text-transform: capitalize;
  margin-left: 10px;
  position: relative;
}

.today-revert {
  font-size: 10px;
  cursor: pointer;
  color: $blue;
  padding: 2px 10px;
  border: 1px solid $blue;
  border-radius: 10px;
  top: -10px;
  margin-left: 10px;

  &:hover {
    background-color: rgba($blue, 0.1);
  }
}

.week-select {
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(#333, 0.1);
  }
}
</style>
