<template>
  <div>
    <Transition name="fade">
      <form
        v-if="edited"
        class="cell-edit"
        @submit.stop.prevent="save"
      >
        <div class="textarea-wrapper">
          <label
            class="textarea-label"
            for="title-textarea"
          >{{ $t('general.title') }}</label>
          <textarea
            id="title-textarea"
            ref="title"
            v-model="title"
            class="day-textarea"
          />
        </div>
        <div class="textarea-wrapper">
          <label
            class="textarea-label"
            for="description-textarea"
          >{{ $t('general.description') }}</label>
          <textarea
            id="description-textarea"
            v-model="description"
            class="day-textarea"
          />
        </div>
        <div class="textarea-wrapper">
          <label
            class="textarea-label"
            for="tags-input"
          >{{ $t('shop.tagsAlergens') }}</label>
          <input
            id="tags-input"
            v-model="tags"
            class="day-textarea"
          >
        </div>

        <div class="d-flex">
          <button
            class="btn btn-sm btn-secondary mr-1"
            type="button"
            :disabled="unchanged"
            @click.prevent.stop="revert"
          >
            <i class="far fa-rotate-left" />
          </button>
          <button
            class="btn btn-sm btn-block btn-primary"
            type="submit"
          >
            <i class="far fa-check" />
          </button>
        </div>
      </form>
    </Transition>
    <div
      v-if="!edited"
      class="content"
    >
      <div class="title">
        {{ updated ? updated.title : initial?.title }}
      </div>
      <div class="description">
        {{ updated ? updated.description : initial?.description }}
      </div>
      <div class="tags">
        {{ updated ? updated.tags?.join(',') : initial?.tags?.join(',') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dietId: String,
    productId: String,
    day: String,
    edited: Boolean,
    initial: Object,
    updated: Object,
  },
  data: () => ({
    description: '',
    title: '',
    tags: '',
  }),
  computed: {
    unchanged() {
      if (!this.initial) {
        return this.title === ''
          && this.description === ''
          && this.tags === '';
      }

      return this.initial
        && this.initial.description === this.description
        && this.initial.title === this.title
        && this.initial.tags.join(',') === this.tags;
    },
    cell() {
      return {
        title: this.title,
        description: this.description,
        tags: this.tags,
      };
    },
  },
  watch: {
    edited(v) {
      if (v) {
        if (this.initial) {
          this.description = this.initial.description;
          this.title = this.initial.title;
          this.tags = this.initial.tags.join(',');
        }

        if (this.updated) {
          this.description = this.updated.description;
          this.title = this.updated.title;
          this.tags = this.updated.tags.join(',');
        }

        setTimeout(() => {
          if (this.$refs.title) {
            this.$refs.title.focus();
          }
        }, 0);
      }
    },
    cell() {
      this.emit();
    },
  },
  methods: {
    emit() {
      if (!this.initial && this.cell.description === ''
      && this.cell.title === ''
      && this.cell.tags === '') {
        this.$emit('update:updated', null);
      }

      if (this.initial
        && this.cell.description === this.initial.description
        && this.cell.title === this.initial.title
        && this.cell.tags === this.initial.tags.join(',')) {
        this.$emit('update:updated', null);
        return;
      }

      this.$emit('update:updated', {
        dietId: this.dietId,
        productId: this.productId,
        day: this.day,
        description: this.cell.description,
        title: this.cell.title,
        tags: this.cell.tags.split(','),
      });
    },
    revert() {
      if (this.initial) {
        this.description = this.initial.description;
        this.title = this.initial.title;
        this.tags = this.initial.tags.join(',');
      } else {
        this.description = '';
        this.title = '';
        this.tags = '';
      }

      this.emit();
      this.$emit('close');
    },
    save() {
      this.$emit('close');
    },
  },
  created() {

  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 5px;
  .title {
    font-weight: 500;
  }
}

.cell-edit {
  position: absolute;
  z-index: 10;
  background: white;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  width: 250px;
  padding: 10px;
  top: -1px;
  left: -1px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
  transform-origin: top left;
}

.fade-enter,
.fade-leave-to {
  transform: scale(0.25);
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  transform: scale(1);
  opacity: 1;
}

.textarea-wrapper {
  position: relative;
  margin-bottom: 8px;

  .textarea-label {
    position: absolute;
    top: -8px;
    left: 8px;
    padding: 0 4px;
    font-size: 12px;
    background: white;
    color: #666;
  }

  .day-textarea {
    padding-top: 8px;
  }
}

.day-textarea {
  border: 1px solid #eee;
  resize: none;
  width: 100%;
  padding: 8px;

  &:focus {
    outline: none;
    border-color: #666;
  }
}
</style>
