<template>
  <div class="d-flex">
    <div class="h-100 sub-catalog-sidebar">
      <div class="py-2">
        <div
          v-for="p in menuNodes"
          :key="p.id"
          class="menu-node"
          :class="{ selected: menuNodeId === p.nodeId }"
          @click="menuNodeId = p.nodeId"
        >
          {{ p.name }}
        </div>
      </div>
      <Loader
        v-if="pending"
      />
    </div>

    <BModal
      v-model="modal"
      size="lg"
      hide-footer
      hide-header
    >
      <DeliveryPlanForm
        :catalog-id="catalogId"
        :delivery-plan="edited"
        @close="modal = false"
      />
    </BModal>
    <div class="flex-grow-1 content">
      <div class="full-height d-flex flex-column">
        <DeliveryPlanCalendar
          v-if="menuNodeId"
          :menu-node-id="menuNodeId"
          :catalog-id="catalogId"
          :move-direction="move"
        />
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryPlanCalendar from '@/components/shop/DeliveryPlanCalendar';
import DeliveryPlanForm from '@/components/shop/DeliveryPlanForm';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    modal: false,
    edited: null,
    menuNodeId: null,
    deliveryPlans: [],
    pending: false,
    move: '',
  }),
  components: {
    DeliveryPlanForm,
    DeliveryPlanCalendar,
  },
  computed: {
    ...mapGetters('shop', [
      'catalogDetails',
    ]),
    menuNodes() {
      const { menuId } = this.catalogDetails.metadata;
      if (!menuId) {
        return [];
      }

      return this.catalogDetails.subCatalogNodes.filter((x) => x.subCatalogId === menuId);
    },
  },
  methods: {
    ...mapActions('shop', [
      'getCatalogListings',
    ]),
  },
  created() {
    this.getCatalogListings({
      params: {
        catalogId: this.catalogId,
      },
    });

    if (this.menuNodes.length > 0) {
      this.menuNodeId = this.menuNodes[0].nodeId;
    }

    this.$emit('page', 'calendar');
  },
};
</script>

<style lang="scss" scoped>
.sub-catalog-sidebar {
  width: 300px;
  overflow: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.selectable {
  display: block;
  cursor: pointer;

  &:hover {
    background-color: rgba($blue, 0.5);
  }
}

.menu-node {
  padding: 5px 10px;
  background-color: #fbfbfb;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;

  &:hover {
    background-color: #ebebeb;
  }

  &.selected {
    background-color: rgba($blue, 0.1);
  }
}

.content {
  border-left: 1px solid rgb(239, 239, 239);
  min-width: 0;
  flex-basis: min-content;
}

.full-height {
  height: calc(100vh - 270px);
}
</style>
