import axios from '@/api/axios';
import Vapi from 'vuex-rest-api';

const store = new Vapi({
  axios,
  state: {
  },
})
  .post({
    action: 'setDeliveryPlanCalendar',
    path: ({ catalogId, menuNodeId }) => `/shop/${catalogId}/delivery-plans/${menuNodeId}`,
  })
  .put({
    action: 'generateDeliveryPlan',
    path: ({ catalogId, menuNodeId }) => `/shop/${catalogId}/delivery-plans/${menuNodeId}`,
  })
  .get({
    action: 'getDeliveryPlanCalendar',
    path: ({ catalogId, menuNodeId }) => `/shop/${catalogId}/delivery-plans/${menuNodeId}`,
  })
  .get({
    action: 'exportDeliveryPlan',
    path: ({ catalogId, menuNodeId }) => `/shop/${catalogId}/delivery-plans/${menuNodeId}/export`,
    requestConfig: {
      responseType: 'blob',
    },
  })
  .post({
    action: 'importDeliveryPlan',
    path: ({ catalogId, menuNodeId }) => `/shop/${catalogId}/delivery-plans/${menuNodeId}/import`,
  })
  .get({
    action: 'getDeliveryPlanSummary',
    path: ({ catalogId }) => `/shop/${catalogId}/delivery-plans/summary`,
  })
  .getStore();

export default store;
