import catalogs from './catalogs';
import deliveryPlans from './delivery-plans';
import listings from './listings';
import orders from './orders';
import products from './products';
import productsets from './productsets_v1';
import subscriptions from './subscriptions';

export default {
  namespaced: true,
  modules: {
    catalogs,
    listings,
    orders,
    products,
    subscriptions,
    productsets,
    deliveryPlans,
  },
};
