<template>
  <div>
    <div
      v-if="nodeName"
      class="d-flex"
      :class="{
        selected: selected.includes(nodeId),
      }"
    >
      <div
        class="catalog-row sub-catalog-caret"
        :class="{ disabled: children.length === 0}"
        @click="expanded = !expanded"
      >
        <i
          v-if="children.length"
          :class="expanded ? 'fas fa-caret-down' : 'fas fa-caret-right'"
        />
      </div>
      <div
        class="catalog-row flex-grow-1"
        @click="$emit('select', { subCatalogId, nodeId })"
      >
        {{ nodeName }}
        <i
          v-if="(accessRule || '').trim()"
          v-tippy
          :content="accessRule"
          class="fa-solid fa-code ml-1 text-secondary"
        />
      </div>
    </div>

    <div
      v-if="children.length > 0 && expanded"
      class="subtree"
      :class="{ 'no-padding': !nodeName }"
    >
      <SubCatalogTree
        v-for="c in children"
        :key="c.nodeId"
        :node-id="c.nodeId"
        :node-name="c.name"
        :sub-catalog-id="subCatalogId"
        :access-rule="c.accessRule"
        :selected="selected"
        :nodes="nodes"
        @select="$emit('select', $event)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubCatalogTree',
  props: {
    nodeId: String,
    nodeName: String,
    subCatalogId: String,
    accessRule: String,
    selected: Array,
    nodes: Array,
  },
  data: () => ({
    expanded: false,
  }),
  computed: {
    children() {
      return this.nodes
        .filter((x) => x.parentId === this.nodeId)
        .sort((a, b) => a.ordinal - b.ordinal);
    },
  },
  created() {
    if (!this.nodeName || this.nodeId === '#all') {
      this.expanded = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.subtree {
  padding-left: 0.7em;
  padding-top: 0.3em;
  padding-bottom: 0.4em;
  border-left: 2px solid #f6f6f6;

  &.no-padding {
    border-left: 2px solid transparent;
    padding-left: 0;
  }
}

.catalog-row {
  padding: 0.2em 1em;
  font-weight: 400;
  font-size: 0.8em;
  background-color: #f6f6f6;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
}

.sub-catalog-caret {
  width: 25px;
  padding: 0;
  align-items: center;
  justify-content: center;
  display: flex;

  &.disabled {
    cursor: default;
    background-color: #f6f6f6 !important;
  }
}

.selected {
  .catalog-row {
    background-color: rgba($blue, 0.2);

    &:hover {
      background-color: rgba($blue, 0.15);
    }
  }
}
</style>
