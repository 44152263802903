<template>
  <div
    class="h-100 d-flex flex-column"
    style="overflow: hidden"
  >
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="page-header">
          <h1>
            {{ $t('shop.addOrders') }}
          </h1>
        </div>
        <div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="cancel"
          >
            ×
          </button>
        </div>
      </div>

      <hr class="mt-1">
      <div v-show="!summary">
        <div class="d-flex justify-content-between">
          <div style="width: 200px" />
          <div style="width: 350px; min-width: 350px">
            <Calendar
              multi-select
              :available-days="availableDays"
              :day-status="dayStatus"
              @highlighted="selectedDays = $event"
              @dates="requestCalendar"
            />
          </div>
          <div
            style="width: 200px"
            class="text-right"
          >
            <button
              class="btn btn-primary btn-lg next-btn mr-1"
              @click="summary = true"
            >
              {{ $t('general.next') }}
              <i class="fas fa-arrow-right px-2" />
            </button>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-9">
            <OrderPayersList
              :catalog-id="catalogId"
              :selected.sync="payers"
              :product-ids="selectedProducts"
              @offer="offer = $event"
            />
          </div>
          <div class="col-3">
            <ProductList
              :catalog-id="catalogId"
              :selected.sync="selectedProducts"
            />
          </div>
        </div>
      </div>
      <template v-if="summary">
        <div class="d-flex justify-content-between">
          <div
            style="width: 200px"
            class="text-left"
          >
            <button
              class="btn btn-secondary btn-lg next-btn mr-1"
              @click="summary = false"
            >
              {{ $t('general.back') }}
              <i class="fas fa-arrow-left px-2" />
            </button>
          </div>
          <div
            style="width: 200px"
            class="text-right"
          >
            <button
              class="btn btn-primary btn-lg next-btn mr-1"
              :disabled="pending"
              @click="save"
            >
              {{ $t('general.save') }}
              <i class="fas fa-check px-2" />
            </button>
          </div>
        </div>
        <hr>
        <BTextarea
          v-model="comment"
          :placeholder="`${$t('shop.comment')}...`"
          class="px-2"
        />
        <hr>
        <OrderSummary
          :payers="payers"
          :selected-days="selectedDays"
          :delivery-plan="deliveryPlan"
          :offer="offer"
          @orders="orders = $event"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Calendar from '@/modules/payer/components/shop/Calendar';
import { mapActions } from 'vuex';
import OrderPayersList from './OrderPayersList';
import OrderSummary from './OrderSummary';
import ProductList from './ProductList';

export default {
  props: {
    catalogId: String,
  },
  data: () => ({
    payers: [],
    selectedDays: [],
    selectedProducts: [],
    availableDays: [],
    summary: false,
    offer: [],
    deliveryPlan: [],
    orders: [],
    comment: '',
    pending: false,
  }),
  components: {
    Calendar,
    ProductList,
    OrderPayersList,
    OrderSummary,
  },
  computed: {
    dayStatus() {
      return this.deliveryPlan.reduce((acc, x) => {
        if (!this.selectedDays.includes(x.day)) {
          return acc;
        }

        const totalCount = this.offer
          .flatMap((y) => y.listingIds)
          .length;

        const count = this.offer
          .flatMap((y) => y.listingIds)
          .filter((lid) => x.listingIds.includes(lid))
          .length;

        acc[x.day] = {
          waiting: totalCount - count,
          new: count,
        };

        return acc;
      }, {});
    },
  },
  methods: {
    ...mapActions('shop', [
      'getProducts',
      'getCatalogListings',
      'getShopCatalog',
      'getDeliveryPlanSummary',
      'updateOrders',
    ]),
    save() {
      this.pending = true;

      this.updateOrders({
        data: {
          catalogId: this.catalogId,
          addedItems: this.orders
            .map((x) => ({
              ...x,
              availabilityId: x.listingId,
              comment: this.comment,
            })),
        },
      })
        .then(() => {
          this.$router.back();
        })
        .finally(() => {
          this.pending = false;
        });
    },
    cancel() {
      this.$router.back();
    },
    requestCalendar({ from, to }) {
      this.getDeliveryPlanSummary({
        params: {
          catalogId: this.catalogId,
          query: {
            from,
            to,
          },
        },
      })
        .then(({ data }) => {
          this.deliveryPlan = data;
          this.availableDays = data.map((x) => x.day);
        });
    },
    request() {
      if (!this.listings || this.listings.length === 0) {
        this.getCatalogListings({
          params: {
            catalogId: this.catalogId,
          },
        });
      }
      if (!this.products || this.products.length === 0) {
        this.getProducts({
          params: {
            catalogId: this.catalogId,
          },
        });
      }

      if (!this.catalogDetails) {
        this.getShopCatalog({
          params: {
            catalogId: this.catalogId,
          },
        });
      }
    },
  },
  created() {
    this.request();
    this.$emit('page', 'form');
  },
};
</script>

<style lang="scss" scoped>
  .close {
    font-size: 40px;
  }
</style>
