<template>
  <div
    class="wide-table-wrapper"
  >
    <div
      class="wide-table"
      :style="{
        'min-width': nameOnly ? null : `${940 + 120*subCatalogsSorted.length}px`
      }"
    >
      <div class="header d-flex">
        <div
          v-if="!hideCheckbox"
          class="checkbox"
          @click="toggleAll"
        >
          <Checkbox
            :value="checkedAll"
            class="px-0"
          />
        </div>
        <div
          v-else
          class="checkbox"
        >
          &nbsp;
        </div>

        <div style="min-width: 300px">
          {{ $t('general.name') }}
        </div>
        <div style="min-width: 200px">
          {{ $t('shop.products') }}
        </div>
        <div
          v-for="s in subCatalogsSorted"
          :key="s.subCatalogId"
          class="assignment-column"
        >
          {{ s.name }}
        </div>
        <div
          v-if="!nameOnly"
          class="time-column"
        >
          {{ $t('shop.listing.limitCount') }}
        </div>
        <div
          v-if="!nameOnly"
          class="time-column"
        >
          {{ $t('shop.orderFrom') }}
        </div>
        <div
          v-if="!nameOnly"
          class="time-column"
        >
          {{ $t('shop.orderTo') }}
        </div>
        <div
          v-if="!nameOnly"
          class="time-column"
        >
          {{ $t('shop.cancelTo') }}
        </div>
        <div
          v-if="!nameOnly"
          style="min-width: 120px; text-align: right;"
        >
          {{ $t('shop.price') }}
        </div>
      </div>

      <ProductListingRow
        v-for="listing in listings"
        :key="listing.id"
        :listing="listing"
        :create="create"
        :update="update"
        :hide-checkbox="hideCheckbox"
        :name-only="nameOnly"
        :checked.sync="checkedListings[listing.id]"
        :selected="listing.id === editedId"
        @select="$emit('toggle', listing.id)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProductListingRow from './ProductListingRow';

export default {
  props: {
    editedId: String,
    listings: Array,
    create: Boolean,
    update: Boolean,
    hideCheckbox: Boolean,
    nameOnly: Boolean,
  },
  data: () => ({
    checkedListings: {},
  }),
  components: {
    ProductListingRow,
  },
  computed: {
    ...mapState({
      catalog: (state) => state.shop.catalogs.catalogDetails,
    }),
    checkedAll() {
      if (this.listings.length === 0) return false;
      return this.listings.every((l) => this.checkedListings[l.id]);
    },
    subCatalogsSorted() {
      return this.catalog.subCatalogs
        .filter((x) => x.subCatalogId !== '#products')
        .sort((a, b) => a.ordinal - b.ordinal);
    },
  },
  methods: {
    toggleAll() {
      this.listings.forEach((l) => {
        this.$set(this.checkedListings, l.id, !this.checkedAll);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  font-size: 12px;
  color: #666;
  font-weight: 500;
  min-width: min-content;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.checkbox {
  width: 32px;
  min-width: 32px;
  height: 20px;
  padding-top: 2px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
}

.wide-table-wrapper {
  overflow: auto;
}

.wide-table {
  margin: 0 10px;
}

.time-column {
  min-width: 100px;
}

.assignment-column {
  flex-grow: 1;
  min-width: 120px;
  max-width: 120px;
  flex-basis: 120px;
}
</style>
