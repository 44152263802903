<template>
  <div
    class="m-3"
  >
    <div class="d-flex align-items-center justify-content-between mb-3">
      <label class="mb-0">
        <i class="far fa-file pr-1" />
        {{ $t('menu.import') }}
      </label>

      <div @click="$emit('close')">
        <i class="fas fa-times secondary-icon" />
      </div>
    </div>
    <div
      class="flex-grow-1"
    >
      <FileUpload
        v-if="!importFile"
        class="upload h-100 mt-1 d-flex align-items-center justify-content-center"
        @upload="validateFile"
      />
      <div
        v-if="importPending"
        class="text-center"
      >
        <Loader />
      </div>
      <div v-if="fileValidation">
        <hr>
        <div class="d-flex justify-content-between">
          <div />
          <div>
            <button
              class="btn btn-sm btn-primary"
              :disabled="importPending"
              @click="upload"
            >
              {{ $t('shop.confirmImport') }}
              <i class="fa-regular fa-arrow-right pl-2" />
            </button>
          </div>
        </div>
        <hr>

        <template v-if="fileValidation.errors && fileValidation.errors.length > 0">
          <div class="font-weight-bold">
            {{ $t('import.errors') }}
          </div>
          <div
            v-for="(error, i) in fileValidation.errors"
            :key="`product_${i}`"
          >
            <ShopImportError v-bind="error" />
          </div>

          <hr>
        </template>
        <template v-if="added.length > 0">
          <div class="font-weight-bold">
            {{ $t('import.new') }}
          </div>

          <ListingsList
            :listings="added"
            hide-checkbox
            create
          />
          <hr>
        </template>
        <template v-if="updated.length > 0">
          <div class="font-weight-bold">
            {{ $t('import.updated') }}
          </div>
          <ListingsList
            :listings="updated"
            hide-checkbox
            update
          />
          <hr>
        </template>
        <template v-if="unchanged.length > 0">
          <div class="font-weight-bold">
            {{ $t('import.unchanged') }}
          </div>
          <ListingsList
            style="opacity: 0.7"
            :listings="unchanged"
            hide-checkbox
          />
          <hr>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/components/FileUpload';
import ShopImportError from '@/components/shop/ShopImportError';
import { mapActions, mapGetters } from 'vuex';
import ListingsList from './ListingsList';

export default {
  props: {
    catalogId: String,
    selectedNodes: Array,
  },
  data: () => ({
    fileValidation: null,
    importFile: null,
    importPending: false,
  }),
  components: {
    FileUpload,
    ListingsList,
    ShopImportError,
  },
  computed: {
    ...mapGetters('shop', [
      'listings',
      'catalogDetails',
    ]),
    added() {
      if (!this.fileValidation) return [];
      return this.fileValidation.listings
        .filter((x) => x.id === '00000000-0000-0000-0000-000000000000');
    },
    updated() {
      if (!this.fileValidation) return [];

      return this.fileValidation.listings
        .filter((x) => x.id !== '00000000-0000-0000-0000-000000000000')
        .map((x) => {
          const ref = this.listings.find((l) => l.id === x.id);

          const fields = [];

          if (ref.name !== x.name) {
            fields.push('name');
          }
          if (ref.description !== x.description) {
            fields.push('description');
          }
          if (ref.orderFrom !== x.orderFrom) {
            fields.push('orderFrom');
          }
          if (ref.orderTo !== x.orderTo) {
            fields.push('orderTo');
          }
          if (ref.cancelTo !== x.cancelTo) {
            fields.push('cancelTo');
          }
          if (ref.countLimit !== x.countLimit) {
            fields.push('countLimit');
          }
          if (ref.price !== x.price || ref.currency !== x.currency) {
            fields.push('price');
          }

          if (ref.productIds.length !== x.productIds.length
            || !ref.productIds.every((id) => x.productIds.includes(id))
            || !x.productIds.every((id) => ref.productIds.includes(id))) {
            fields.push('productIds');
          }

          this.catalogDetails.subCatalogs.forEach((sc) => {
            const old = ref.assignments
              .filter((a) => a.subCatalogId === sc.subCatalogId)
              .map((a) => a.nodeId)
              .sort((a, b) => a.localeCompare(b))
              .join(';');
            const up = x.assignments
              .filter((a) => a.subCatalogId === sc.subCatalogId)
              .map((a) => a.nodeId)
              .sort((a, b) => a.localeCompare(b))
              .join(';');

            if (old !== up) {
              fields.push(sc.subCatalogId);
            }
          });

          return {
            ...x,
            fields,
          };
        })
        .filter((x) => x.fields.length > 0);
    },
    unchanged() {
      if (!this.fileValidation) return [];
      const updatedIds = this.updated.map((x) => x.id);
      return this.fileValidation.listings
        .filter((x) => x.id !== '00000000-0000-0000-0000-000000000000' && !updatedIds.includes(x.id));
    },
  },
  methods: {
    ...mapActions('shop', [
      'offerImportValidate',
      'offerImport',
    ]),
    validateFile(file) {
      const formData = new FormData();
      formData.append('file', file);

      for (let i = 0; i < this.selectedNodes; i += 1) {
        formData.append(`nodeIds[${i}]`, this.selectedNodes[i].code);
      }

      this.importFile = file;
      this.importPending = true;

      this.offerImportValidate({
        params: {
          catalogId: this.catalogId,
        },
        data: formData,
      })
        .then(({ data }) => {
          this.fileValidation = data;
        })
        .finally(() => {
          this.importPending = false;
        });
    },
    upload() {
      const formData = new FormData();
      formData.append('file', this.importFile);

      for (let i = 0; i < this.selectedNodes; i += 1) {
        formData.append(`nodeIds[${i}]`, this.selectedNodes[i].code);
      }

      this.importPending = true;

      this.offerImport({
        params: {
          catalogId: this.catalogId,
        },
        data: formData,
      })
        .finally(() => {
          this.importPending = false;
        });
    },
  },
};
</script>
